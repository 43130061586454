@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  font-weight: 600;
  line-height: 1;
  &__inner{
    max-width: 65%;
    width: 100%;
    margin-right: 4rem;
  }
  &__menu{
    justify-content: space-between;
  }
  li{
    a{
      display: flex;
      height: 100%;
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.3;
      span{
        display: flex;
        align-items:center;
      }
      @include mq(sm-){
        &:hover{
          color: $accent_color;
        }
      }
    }
  }
  &__inquery{
    max-width: 215px;
    width: 100%;
    text-align: center;
    a{
      display: block;
      background:$accent_color;
      color: #fff;
      padding:3.5rem 1rem;
      border:2px solid $accent_color;
      span{
        position: relative;
        padding-left: 4.3rem;
        &:before{
          content: "";
          background:url('/recruit/assets/img/common/icon_mail.png') no-repeat;
          width: 32px;
          height: 25px;
          display: block;
          position: absolute;
          left: 0;
          top:50%;
          transform: translateY(-50%);
          transition-duration: 0.3s;
        }
      }
      &:hover{
        background:#fff;
        color: $accent_color;
        span:before{
          background:url('/recruit/assets/img/common/icon_mail_bl.png') no-repeat;
        }
      }
    }
  }
  @include mq(md){
    &__inner{
      max-width: 71%;
    }
    li{
      a{
        font-size: 1.4rem;
      }
    }
    &__inquery{
      max-width: 160px;
    }
  }
  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
  }
  @include mq(sm){
    background: rgba(0,0,0,.8);
    padding: 10rem 3rem 5rem;
    right: 0;
    transform:translateX(100%);
    width: 100%;
    &__inner{
      max-width: 100%;
    }
    &__menu{
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.-main{
        display: block;
        margin-bottom: $margin_sm;
        li{
          width: 100%;
        }
        a{
          font-size: 1.25em;
          padding: 1em;
          text-align: center;
          color: #fff;
          span{
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
    &__inquery{
      margin:auto;
      max-width: 300px;
      a{
        padding: 3rem 1rem;
      }
    }
    .slideout-open &{
      transform:translateX(0);
    }
    .copy{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      font-size: .875em;
      text-align: center;
      padding: 1em 0;
    }
  }
}