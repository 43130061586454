@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  position: relative;
  background: $font-color;
  color: #fff;
  padding:4rem 0;
  .c-inner{
    justify-content: space-between;
    align-items: center;
    .lbox{
      align-items:center;
      justify-content: center;
      margin-top: -1em;
      margin-left: -1em;

      > * {
        margin-top: 1em;
        margin-left: 1em;
      }
      > a {
        display: block;

        &:hover {
          opacity: .8;
        }
      }
    }

    .copy{
      margin-top: 1.5em;
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.3;
    }
  }

  @include mq(sm){
    .c-inner{
      .lbox{
        width: auto;
        justify-content: space-between;

        > a {
          width: 6em;
        }
      }
      .copy{
        font-size: 1.4rem;
        text-align: center;
        width: 100%;
      }
    }
  }
  @include mq(xs){
    .c-inner{
      text-align: center;
      .lbox{
        text-align: center;
        justify-content: center;

        .c-btn01 {
          width: 100%;
          margin-bottom: 1em;
        }
      }
    }
  }
  @include mq(ss){
    .c-inner{
      text-align: center;

      .lbox{
        margin-left: -.6em;

        > * {
          margin-left: .6em;
        }

        > a {
          width: calc(25% - .6em);
        }
      }
    }
  }
}