@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: $base_width;
  max-width: $base_width_max;
  margin-left: auto;
  margin-right: auto;
  &.-full {
    width: $base_width_max;
  }
  @include mq(ss) {
    max-width: 90%;
  }
}
// .c-key_under {
// }
.c-fullpage {
  width: 100%;
  height: 100vh;
  // scroll-snap-type: y mandatory;
  // overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  &__sec {
    width: 100%;
    min-height: 100vh;
    // scroll-snap-align: start;
    filter: blur($margin_ss);
    transition: filter 0.6s 0.6s;
    .c-inner {
      padding: $margin_md * 2 0;
      max-width: 80%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      @include mq(ss) {
        max-width: 90%;
        margin-right: 10%;
        padding: $margin_md * 2 $margin_ss;
      }
    }
    &.is-show {
      filter: blur(0);
    }
  }
  &__pager {
    position: fixed;
    right: $margin_ss * 2;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    li {
      position: relative;
      &:nth-child(n + 2) {
        padding-top: $margin_ss * 2;
        &:before {
          position: absolute;
          content: "";
          left: 0.3125em;
          top: $margin_ss/4;
          width: 1px;
          height: $margin_ss * 3/2;
          background: #fff;
        }
      }
      a {
        display: block;
        width: calc(0.625em + 1px);
        height: calc(0.625em + 1px);
        border: solid 2px #fff;
        border-radius: 50%;
      }
      &.is-act {
        a {
          background: #fff;
        }
      }
    }
  }
  @include mq(w1400) {
    &__pager {
      right: $margin_ss;
    }
  }
}

.c-stripe {
  background: url("../img/common/c-stripe.jpg") repeat;
}
.c-bg_color {
  background: $sub_color;
}
.c-img_responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.c-boxpd {
  @include mq(sm-) {
    padding-top: 6.25em;
    padding-bottom: 6.25em;

    &_sm {
      padding-top: 5em;
      padding-bottom: 5em;
    }
    &_lg {
      padding-top: 9.4em;
      padding-bottom: 9.4em;
    }
  }
  @include mq(sm) {
    padding-top: 4em;
    padding-bottom: 4em;

    &_sm {
      padding-top: 3em;
      padding-bottom: 3em;
    }
    &_lg {
      padding-top: 6em;
      padding-bottom: 6em;
    }
  }
}

/*-------------------------------
  under > key
-------------------------------*/
.p-under_key {
  padding: 5em 0;
  text-align: center;
  line-height: 1;
  .en {
    font-size: 6rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  @include mq(xs) {
    padding: 3em 0;
    .en {
      font-size: 4rem;
    }
    h2 {
      font-size: 2.2rem;
    }
  }
}

/*--------------------------------------------
  TO TOP
--------------------------------------------*/
.totop {
  position: absolute;
  top: -11.5rem;
  right: 5rem;
  width: 8.1rem;
  height: 9.5rem;
  animation: totop 3s linear 0.5s infinite;

  > a {
    display: block;
  }
  @include mq(sm) {
    right: 2rem;
    top: -8rem;
  }
  @include mq(xs) {
    top: -7rem;
  }
  @keyframes totop {
    0% {
      transform: translateY(0);
    }
    10% {
      transform: translateY(-1rem);
    }
    20% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-0.3rem);
    }
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}
/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl01 {
  line-height: 1;
  text-align: center;
  margin-bottom: 4rem;
  transform: translateY(6rem);
  opacity: 0;
  transition-duration: 0.6s;
  span {
    font-family: $base_font_en;
    font-size: 7.2rem;
    position: relative;
    display: inline-block;
    margin-bottom: 1.5rem;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }
    &:before {
      background: url("../img/common/c-ttl_l.png") no-repeat;
      width: 61px;
      height: 56px;
      left: -1.1em;
      top: 0;
      animation: t-deco 2s linear 0s infinite;
    }
    &:after {
      background: url("../img/common/c-ttl_r.png") no-repeat;
      width: 80px;
      height: 65px;
      right: -1.2em;
      top: 0;
      animation: tail 3s linear 0.5s infinite;
    }
  }
  h2 {
    font-size: 3rem;
  }
  @keyframes t-deco {
    0% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.9);
    }
    25% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes tail {
    0% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
    10% {
      transform: rotate(20deg);
      transform-origin: 0 50%;
    }
    20% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
    25% {
      transform: rotate(10deg);
      transform-origin: 0 50%;
    }
    30% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
    50% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
    75% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
    100% {
      transform: rotate(0);
      transform-origin: 0 50%;
    }
  }
  @include mq(sm) {
    margin-bottom: 3rem;
    &.-imgtxt{
      span{
        font-size: 1rem;
        margin-bottom: 2.5em;
      }
      img{
        width: 70%;
      }
    }
    span {
      font-size: 5rem;
      &:before,
      &:after {
        background-size: contain;
      }
      &:before {
        width: 44px;
        height: 40px;
        left: -1em;
      }
      &:after {
        width: 54px;
        height: 45px;
        right: -1.2em;
      }
    }
    h2 {
      font-size: 2rem;
    }
  }
  @include mq(xs) {
    span {
      font-size: 4rem;
      margin-bottom: 0.3em;
      &:before {
        width: 32px;
        height: 30px;
      }
      &:after {
        width: 43px;
        height: 36px;
        right: -1.4em;
      }
    }
    h2 {
      font-size: 1.8rem;
    }
  }
}
.is-anime {
  .c-ttl01 {
    transform: translateY(0rem);
    opacity: 1;
  }
}

.c-ttl02 {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  span {
    display: block;
    font-size: 0.6em;
    margin-top: 0.5em;
  }
  @include mq(sm) {
    font-size: 3rem;
  }
  @include mq(xs) {
    font-size: 2.3rem;
    line-height: 1.3;
    margin-bottom: 2rem;
  }
}

.c-ttl03 {
  position: relative;
  margin-bottom: 1.6em;
  padding: 0.6em;
  padding-left: 3em;
  background-color: $main_color;
  border-radius: 0.5em;
  border-left: 2.3rem solid $font_color;
  font-size: 3rem;
  line-height: 1.3;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0.6em;
    bottom: 0;
    margin: auto;
    width: 4.9rem;
    height: 3.7rem;
    background: url("../img/common/pad.png") center/contain no-repeat;
  }

  @include mq(sm) {
    margin-bottom: 1.3em;
    padding: 0.6em;
    padding-left: 2.5em;
    border-left-width: 1.5rem;
    font-size: 2.4rem;

    &::before {
      width: 3.5rem;
      height: 3rem;
    }
  }
  @include mq(xs) {
    border-left-width: 1.2rem;
    font-size: 2.2rem;

    &::before {
      width: 3.2rem;
      height: 2.6rem;
    }
  }
}

.c-ttl04 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5em;

  > .num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25em;
    height: 1.25em;
    margin-bottom: 0.3em;
    background-color: $main_color;
    border-radius: 50%;
    font-weight: 400;
    font-family: $base_font_en;
    font-size: 2.4rem;
    line-height: 1;
  }

  > .main {
    padding-bottom: 0.2em;
    border-bottom: 4px solid $font_color;
    font-size: 3rem;
    line-height: 1.3;
  }

  @include mq(sm) {
    margin-bottom: 1.5em;

    > .num {
      font-size: 2rem;
    }

    > .main {
      border-width: 2px;
      font-size: 2.4rem;
    }
  }
  @include mq(xs) {
    > .num {
      font-size: 1.8rem;
    }

    > .main {
      font-size: 2.2rem;
    }
  }
}

.en {
  font-family: $base_font_en;
}

.c-lead {
  font-size: 1.5em;
  margin-bottom: 2.5em;
  font-weight: 400;
  @include mq(md) {
    font-size: 1.35em;
    margin-bottom: 2em;
  }
  @include mq(ss) {
    line-height: 1.4;
  }
}

/*--------------------------------------------
  DL
--------------------------------------------*/
.c-dl_label {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6em;
  margin-left: -1.6em;

  &__item {
    display: flex;
    align-items: center;
    width: calc((100% - 3.2em) / 2);
    margin-top: 1.6em;
    margin-left: 1.6em;
    padding: 1.3em;
    background-color: $sub_color;
    border-radius: 0.5em;

    > dt {
      width: 11rem;
      padding: 0.4em 0.2em;
      background-color: $main_color;
      border-radius: 0.4em;
      text-align: center;
      font-size: 2.2rem;
      line-height: 1.1;
    }
    > dd {
      width: calc(100% - 11rem);
      padding-left: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }

  @include mq(sm) {
    margin-top: 2%;
    margin-left: -2%;

    &__item {
      width: calc((100% - 4%) / 2);
      margin-top: 2%;
      margin-left: 2%;
      padding: 1em;

      > dt {
        width: 9rem;
        font-size: 1.8rem;
      }
      > dd {
        width: calc(100% - 9rem);
        padding-left: 1em;
        font-size: 1.6rem;
      }
    }
  }
  @include mq(xs) {
    &__item {
      width: 100%;
    }
  }
}

/*--------------------------------------------
  COLUMN
--------------------------------------------*/
.c-col {
  $amp: &;
  display: flex;
  flex-wrap: wrap;
  margin: -5em -2.5em 0;

  &__item {
    width: 50%;
    margin-top: 5em;
    padding: 0 2.5em;
  }

  &.-gallery {
    margin: -0.8em -0.4em 0;

    #{$amp}__item {
      margin-top: 0.8em;
      padding: 0 0.4em;
    }
  }
  &.-gallery_col3 {
    margin: -1.2em -0.6em 0;

    #{$amp}__item {
      width: 33.3333%;
      margin-top: 1.2em;
      padding: 0 0.6em;
    }
  }

  @include mq(sm) {
    margin: -2em -1.5% 0;

    &__item {
      margin-top: 2em;
      padding: 0 1.5%;
    }

    &.-gallery {
      margin: -0.4em -0.2em 0;

      #{$amp}__item {
        margin-top: 0.4em;
        padding: 0 0.2em;
      }
    }
    &.-gallery_col3 {
      margin: -0.8em -0.4em 0;

      #{$amp}__item {
        margin-top: 0.8em;
        padding: 0 0.4em;
      }
    }
  }
  @include mq(ss) {
    &__item {
      width: 100%;
    }

    &.-gallery {
      #{$amp}__item {
        width: 50%;
      }
    }
    &.-gallery_col3 {
      #{$amp}__item {
        width: 50%;
      }
    }
  }
}

/*--------------------------------------------
  LIST
--------------------------------------------*/
.c-list_label {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.2rem;
  margin-left: -1.2rem;

  &__list {
    margin-top: 1.2rem;
    margin-left: 1.2rem;
    padding: 0.4em 0.8em;
    background-color: $sub_color;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.1;
  }

  @include mq(sm) {
    margin-top: -1rem;
    margin-left: -1rem;

    &__list {
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 2rem;
    }
  }
  @include mq(xs) {
    &__list {
      font-size: 1.8rem;
    }
  }
}

.c-list_dot {
  &__list {
    position: relative;
    padding-left: 1.6em;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.3;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0.3em;
      left: 0.4em;
      width: 0.7em;
      height: 0.7em;
      background-color: $main_color;
      border-radius: 50%;
    }

    & + & {
      margin-top: 0.4em;
    }
  }

  @include mq(sm) {
    &__list {
      font-size: 2rem;
    }
  }
  @include mq(xs) {
    &__list {
      font-size: 1.8rem;
    }
  }
}

/*--------------------------------------------
  TAB
--------------------------------------------*/
.c-tab.c-flex {
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto 8rem;
  li {
    width: 48%;
    a {
      background: $font_color;
      display: block;
      color: #fff;
      border-radius: 10px;
      text-align: center;
      line-height: 1;
      padding: 2em 1em;
      border: 2px solid $font_color;
      font-size: 2rem;
      font-weight: 600;
      @include mq(sm-) {
        &:hover {
          background: #fff;
          color: $font-color;
        }
      }
    }
  }
  @include mq(xs) {
    margin: 0 auto 4rem;
    li {
      a {
        padding: 1em;
      }
    }
  }
}

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  &.-fx {
    display: flex;
    justify-content: center;
    align-items: center;
    &.-justify {
      justify-content: space-between;
    }
    &.-right {
      justify-content: flex-end;
    }
  }
  &.-center {
    text-align: center;
  }
  &.-right {
    text-align: right;
  }
}

.c-btn01 {
  a {
    display: block;
    background: $main_color;
    border-radius: 10px;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 2rem;
    border: 2px solid $main_color;
    span {
      position: relative;
      padding-left: 2em;
      &:before {
        content: "";
        background: url("../img/common/icon_arrow02.png") no-repeat;
        width: 29px;
        height: 38px;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:hover {
      background: $font_color;
      color: $main_color;
    }
  }
}

.c-btn02 {
  max-width: 350px;
  margin: auto;
  line-height: 1;
  a {
    display: block;
    background: $accent_color;
    color: #fff;
    border-radius: 10px;
    padding: 2em 1em;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    border: 2px solid $accent_color;
    @include mq(sm-) {
      &:hover {
        background: #fff;
        color: $accent_color;
      }
    }
    &.-bl{
      background: $font-color;
      border: 2px solid $font-color;
      @include mq(sm-) {
        &:hover {
          background: #fff;
          color: $font-color;
        }
      }
    }
  }
  @include mq(xs){
    width: 92%;
    a{
      padding: 1.5em;
    }
  }
}

.c-btn03 {
  a {
    display: block;
    max-width: 50rem;
    background: $main_color;
    border-radius: 10px;
    text-align: center;
    font-size: 3.4rem;
    font-weight: 600;
    padding: 2rem;
    border: 2px solid $main_color;
    span {
      display: inline-block;
      position: relative;
      padding: 0.8em;
      padding-right: 2em;
      &:before {
        content: "";
        background: url("../img/common/icon_arrow03.png") center/contain no-repeat;
        width: 4.7rem;
        height: 6.5rem;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:hover {
      background: $font_color;
      color: $main_color;
    }
  }

  @include mq(sm) {
    a {
      max-width: 32rem;
      font-size: 2.4rem;

      span {
        padding: 0.6em;
        padding-right: 2em;

        &:before {
          width: 4rem;
          height: 6rem;
        }
      }
    }
  }
  @include mq(ss) {
    a {
      max-width: 26rem;
      padding: 1.5rem;
      font-size: 2.4rem;

      span {
        padding: 0.4em;
        padding-right: 1.5em;

        &:before {
          width: 3rem;
          height: 5rem;
        }
      }
    }
  }
}

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tbl {
  margin-bottom: 5rem;
  .input_middle {
    input[type="text"] {
      width: 70%;
    }
  }
  .input_short {
    select {
      width: 40%;
    }
  }
  input[type="radio"] {
    width: 1em;
    height: 1em;
  }
  .address {
    .input_short {
      width: 50%;
    }
  }
  .check {
    display: flex;
    max-width: 50rem;
    .form_input {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.5em;
      }
    }
    .year {
      width: 40%;
    }
    .month {
      width: 30%;
      margin-left: 1em;
    }
    .day {
      width: 30%;
      margin-left: 1em;
    }
  }
  tr {
    border: 2px solid #fff;
  }
  th {
    padding: 2em 0.5em;
    background: $main_color;
    width: 25%;
    line-height: 1.6;
    font-size: 1.8rem;
    span {
      background: $accent_color;
      display: inline-block;
      border-radius: 5px;
      color: #fff;
      font-size: 1.3rem;
      line-height: 1;
      padding: 0.5em;
      margin-left: 1em;
    }
  }
  td {
    width: 75%;
    padding: 2em 2.5em;
  }
  @include mq(sm) {
    .check {
      width: 100%;
    }
    th {
      font-size: 1.6rem;
    }
  }
  @include mq(xs) {
    margin-bottom: 1rem;
    .input_middle {
      input[type="text"] {
        width: 100%;
      }
    }
    select {
      padding: 0.8em 0.3em;
    }
    .input_short {
      select {
        width: 100%;
      }
    }
    tr,
    th,
    td {
      display: block;
      width: 100%;
    }
    th {
      padding: 0.5em;
    }
    td {
      padding: 1em 0.5em;
    }
  }
}
.submit_area {
  text-align: center;
  input[type="submit"] {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding: 1em;
    transition-duration: 0.3s;
  }
  .btn_submit {
    max-width: 30rem;
    width: 100%;
    border: 2px solid $accent_color;
    background: $accent_color;
    margin: auto;
    @include mq(sm-) {
      &:hover {
        color: $accent_color;
        background: #fff;
      }
    }
  }
  .back {
    background: #777;
    max-width: 30rem;
    width: 100%;
    border: 2px solid #777;
    margin: auto;
    @include mq(sm-) {
      &:hover {
        color: #777;
        background: #fff;
      }
    }
  }
}

.c-tbl_responsive {
  @include mq(sm) {
    &__inner {
      overflow-x: auto;
      overflow-y: visible;
      table {
        min-width: 100%;
        width: auto;
        th,
        td {
          white-space: nowrap;
        }
      }
      &::-webkit-scrollbar {
        height: 0.6rem;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: $main_color;
      }
    }
    &:after {
      display: block;
      content: "横にスクロールできます";
      text-align: center;
      margin-top: 0.5em;
    }
  }
}

/*--------------------------------------------
 BLOCK - NEWS
--------------------------------------------*/
.c-news {
  padding: 8rem;
  position: relative;
  .c-inner {
    max-width: 100%;
  }
  &__list {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto 2em;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    li {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      margin: auto;
      &:nth-child(n + 2) {
        margin-top: 1em;
      }
      a {
        padding: 2em 1.3em;
        flex-wrap: nowrap;
        align-items: center;
        p {
          line-height: 1.4;
          position: relative;
          &:before {
            content: "";
            background: $main_color;
            height: 4px;
            width: 0%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            transition-duration: 0.3s;
          }
        }
        &:hover {
          p:before {
            width: 100%;
          }
        }
      }
      .date {
        font-size: 3rem;
        line-height: 1;
        margin-right: 1em;
      }
      .cate {
        font-size: 1.8rem;
        color: #fff;
        background: $font_color;
        line-height: 1;
        padding: 0.3em 0.8em;
        margin-right: 1em;
      }
    }
  }
  &.is-anime {
    .c-ttl01 {
      transition-delay: 1.3s;
    }
    .c-news__list {
      transform: translateY(0rem);
      transition-delay: 1.5s;
      opacity: 1;
    }
  }
  @include mq(sm) {
    padding: 6rem 0;
    &__list {
      max-width: 92%;
      li {
        .date {
          font-size: 2.5rem;
        }
        .cate {
          font-size: 1.6rem;
        }
      }
    }
  }
  @include mq(xs) {
    &__list {
      li {
        a {
          flex-wrap: wrap;
          .date {
            font-size: 2rem;
          }
          p {
            width: 100%;
            margin-top: 0.5em;
          }
        }
      }
    }
  }
}

/*--------------------------------------------
 BLOCK - ENTRY
--------------------------------------------*/

.c-entry {
  padding: $margin_ll 0 0;
  &__btn.c-flex {
    justify-content: center;
    position: relative;
    padding-top: 13rem;
    a {
      position: relative;
      display: block;
      border-radius: 15px;
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 1.4;
      padding: 1.8em 0.5em;
    }
    div {
      transform: translateY(7rem);
      opacity: 0;
      transition-duration: 0.6s;
    }
    .n-graduate {
      transition-delay: 0.5s;
      &:before {
        background: url("../img/index/idx_entry_01.png") no-repeat;
        background-size: contain;
        width: 240px;
        height: 256px;
        transition-duration: 0.3s;
      }
      &:after {
        background: url("../img/common/icon_arrow_y.png") no-repeat;
        background-size: contain;
      }
      a {
        background: $font_color;
        color: #fff;
      }
    }
    .part {
      transition-delay: 1s;
      &:before {
        background: url("../img/index/idx_entry_02.png") no-repeat;
        background-size: contain;
        width: 217px;
        height: 254px;
        transition-duration: 0.3s;
      }
      &:after {
        background: url("../img/common/icon_arrow_bk.png") no-repeat;
        background-size: contain;
      }
      a {
        background: $main_color;
      }
    }
  }
  &__item {
    width: 48%;
    text-align: center;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
    }
    &:after {
      content: "";
      width: 26px;
      height: 40px;
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      z-index: 3;
    }
  }
  &__stop {
    transition-delay: 1.5s;
    margin-top: 1em;
    text-align: center;
    font-size: 2vw;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0;
  }
  &.is-anime {
    .c-entry__btn.c-flex {
      div {
        transform: translateY(0rem);
        opacity: 1;
      }
    }
    .c-entry__stop {
      opacity: 1;
    }
  }
  @include mq(sm-) {
    &__btn.c-flex {
      .c-entry__item:hover {
        &:before {
          top: -10%;
        }
      }
    }
  }
  @include mq(sm) {
    padding: $margin_lg 0;
    &__btn.c-flex {
      .n-graduate {
        &:before {
          width: 190px;
          height: 215px;
        }
      }
      .part {
        &:before {
          width: 190px;
          height: 230px;
        }
      }
      a {
        font-size: 2.3vw;
      }
    }
    &__item {
      &:after {
        width: 16px;
        height: 25px;
      }
    }
    &__stop {
      font-size: 3vw;
    }
  }
  @include mq(xs) {
    padding: $margin_md 0 10rem;
    &__btn.c-flex {
      display: block;
      padding-top: 10rem;
      .n-graduate {
        margin-bottom: 12rem;
        &:before {
          width: 160px;
          height: 180px;
        }
      }
      .part {
        &:before {
          width: 150px;
          height: 187px;
        }
      }
      a {
        font-size: 2rem;
      }
    }
    &__item {
      width: 100%;
    }
    &__stop {
      font-size: 2.4rem;
    }
  }
}

/*--------------------------------------------
 BLOCK - FORM
--------------------------------------------*/
.c-form_box {
  $amp: &;
  &__sheet {
    margin-bottom: $margin_md;
    tr {
      border-bottom: solid 1px rgba(255, 255, 255, 0.4);
      &.-top {
        th,
        td {
          vertical-align: top;
        }
        #{$amp}__label {
          padding-top: 0.5em;
        }
      }
    }
    th,
    td {
      padding: 2em;
      vertical-align: middle;
      text-align: left;
      line-height: 1.6;
      font-weight: 400;
    }
    th {
      width: 25%;
    }
    td {
      width: 85%;
    }
    @include mq(sm) {
      tr {
        display: block;
        padding: 1.75em 0;
      }
      th,
      td {
        display: block;
        padding: 0;
        width: 100%;
      }
      th {
        line-height: 1;
        margin-bottom: 1.5em;
      }
    }
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.-require {
      &:after {
        content: "【必須】";
        color: $font_color;
        margin-left: 0.5em;
        @include mq(sm) {
          @include mq(xs-) {
            display: block;
            font-size: 0.875em;
            margin-left: 0;
          }
        }
      }
    }
    @include mq(md) {
      @include mq(sm-) {
        display: block;
        &.-require {
          &:after {
            display: block;
            margin-left: 0;
            text-indent: -0.5em;
          }
        }
      }
    }
  }
  #{$amp}__error {
    margin-top: 0.5em;
    .error {
      color: $font_color;
      font-size: 1em;
      &:before {
        content: "※";
        margin-right: 0.25em;
      }
    }
  }
  &__submit {
    @include mq(sm-) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
    @include mq(xs-) {
      .c-btn_pl {
        width: 300px;
        &:nth-child(n + 2) {
          margin-right: $margin_ss;
        }
      }
    }
    @include mq(sm) {
      justify-content: center;
      margin-top: $margin_sm;
      flex-direction: row;
    }
    @include mq(xs) {
      flex-direction: row;
      .c-btn_pl {
        display: block;
        margin: 0 auto;
        width: 48%;
        &:nth-child(n + 2) {
          margin-left: 4%;
          margin-right: 0;
        }
      }
    }
    @include mq(ss) {
      display: block;
      .c-btn_pl {
        width: 100%;
        &:nth-child(n + 2) {
          margin-left: 0;
          margin-top: $margin_sm;
        }
      }
    }
  }
  &__agreement {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a {
      text-decoration: underline;
      @include mq(sm-) {
        &:hover {
          text-decoration: none;
        }
      }
    }
    #{$amp}__input {
      &.-check {
        padding: 0;
        margin-right: 0.75em;
        .mwform-checkbox-field {
          display: block;
          margin-top: 0;
          &:not(:last-child) {
            margin-right: 0;
          }
        }
        input[type="checkbox"] {
          + .mwform-checkbox-field-text {
            padding-left: 1.75em;
            font-size: 0;
          }
        }
      }
    }
    #{$amp}__error {
      @include mq(sm-) {
        margin-top: 0;
        margin-left: 1em;
      }
      @include mq(sm-) {
        width: 100%;
        margin-top: 1em;
        margin-left: 0;
      }
    }
  }
  &__input {
    textarea {
      height: 12em;
    }
    @include mq(sm-) {
      &.-short {
        .text_field {
          width: 40%;
        }
      }
      &.-middle {
        .text_field {
          width: 60%;
        }
      }
      &.-long {
        .text_field {
          width: 80%;
        }
      }
    }
    &.-radio {
      line-height: 1;
      padding: 0.5em 0;
      @include mq(ss-) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-radio-field {
        display: block;
        margin-top: 1em;
        &:not(:last-child) {
          margin-right: 2.5em;
        }
        + .mwform-radio-field {
          margin-left: 0;
        }
      }
      input[type="radio"] {
        display: none;
        + .mwform-radio-field-text {
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
          }
          &:before {
            border: solid 1px #aaa;
          }
          &:after {
            background: $font_color;
            transform: scale(0.35);
            visibility: hidden;
          }
        }
        &:checked {
          + .mwform-radio-field-text {
            &:after {
              visibility: visible;
            }
          }
        }
      }
    }
    &.-check {
      line-height: 1;
      padding: 0.5em 0;
      @include mq(ss-) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      .mwform-checkbox-field {
        display: block;
        margin-top: 1.6rem;
        &:not(:last-child) {
          margin-right: 4rem;
        }
        ~ .mwform-checkbox-field {
          margin-left: 0;
        }
      }
      input[type="checkbox"] {
        display: none;
        + .mwform-checkbox-field-text {
          display: block;
          position: relative;
          min-width: 1.6rem;
          min-height: 1.6rem;
          padding-left: 2.4rem;
          &:before,
          &:after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 1.6rem;
            height: 1.6rem;
          }
          &:before {
            border: solid 2px #e0e0e0;
          }
          &:after {
            height: 1rem;
            width: 1.6rem;
            border-left: solid 3px $font_color;
            border-bottom: solid 3px $font_color;
            transform-origin: left bottom;
            transform: rotate(-45deg) translate(25%, 75%);
            visibility: hidden;
          }
        }
        &:checked {
          + .mwform-checkbox-field-text {
            &:after {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .mw_wp_form_preview {
    #{$amp}__label {
      padding: 0;
    }
    #{$amp}__agreement {
      display: none;
    }
    #{$amp}__sheet {
      tr {
        &.-top {
          th,
          td {
            vertical-align: middle;
          }
          #{$amp}__label {
            padding-top: 0;
          }
        }
      }
    }
    #{$amp}__submit {
      justify-content: flex-end;
    }
  }
}
.-thanks {
  text-align: center;
  .c-ttl_pl {
    margin-bottom: $margin_md;
  }
  @include mq(xs) {
    .c-ttl_pl {
      font-size: 2rem;
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover {
      object-fit: cover;
      object-position: center center;
      font-family: "object-fit: cover;object-position:center center;";
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: "object-fit: contain;object-position:center center;";
    }
  }
}

/*--------------------------------------------
  FOLLOW
--------------------------------------------*/
.c-follow{
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 999;
  width: 20vw;
  &__box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff6c4;
    padding: .5em .75em ;
    border: 3px solid $main_color;
    border-radius: 10px;
    figure{
      width: 25%;
      background: #fff;
      img{
        width: 100%;
      }
    }
  }
  &__txt{
    width: 72%;        
    span{
      font-size: 1.25em;
      font-weight: 500;
    }
    p{
      line-height: 1.34;
      font-size: .875em;
    }
  }
  @include mq(md){
    width: 30vw;
  }
  @include mq(sm){
    width: 100%;
    bottom: 0;
    &__box{
      padding: .5em .75em;
      figure{
        width: 20%;
        text-align: center;
        img{
          max-width: 110px;
        }
      }
    }
    &__txt{
      width: 76%;
      span{
        font-size: 1em;
      }
    }
  }
  @include mq(xs){
    &__box{
      padding: .5em;
    }
  }
}

/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-ta_justify {
  @include mq(ss-) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}

.c-flex {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}

.c-mgt {
  &0 {
    margin-top: 0;
  }

  &_ss {
    margin-top: 0.6em;
  }
  &_xs {
    margin-top: 1em;
  }
  &_sm {
    margin-top: 1.5em;
  }
  &_md {
    margin-top: 2em;
  }
  &_lg {
    margin-top: 2.5em;
  }
  &_xl {
    margin-top: 3em;
  }
  &_xxl {
    margin-top: 3.5em;
  }

  @include mq(sm) {
    &_ss {
      margin-top: 0.4em;
    }
    &_xs {
      margin-top: 0.8em;
    }
    &_sm {
      margin-top: 1.2em;
    }
    &_md {
      margin-top: 1.6em;
    }
    &_lg {
      margin-top: 2em;
    }
    &_xl {
      margin-top: 2.4em;
    }
    &_xxl {
      margin-top: 2.8em;
    }
  }
}

.c-mgl {
  &0 {
    margin-left: 0;
  }
}

.c-mgr {
  &0 {
    margin-right: 0;
  }
}

.c-mgb {
  &0 {
    margin-bottom: 0;
  }

  &_ss {
    margin-bottom: 0.6em;
  }
  &_xs {
    margin-bottom: 1em;
  }
  &_sm {
    margin-bottom: 1.5em;
  }
  &_md {
    margin-bottom: 2em;
  }
  &_lg {
    margin-bottom: 2.5em;
  }
  &_xl {
    margin-bottom: 3em;
  }
  &_xxl {
    margin-bottom: 3.5em;
  }

  @include mq(sm) {
    &_ss {
      margin-bottom: 0.4em;
    }
    &_xs {
      margin-bottom: 0.8em;
    }
    &_sm {
      margin-bottom: 1.2em;
    }
    &_md {
      margin-bottom: 1.6em;
    }
    &_lg {
      margin-bottom: 2em;
    }
    &_xl {
      margin-bottom: 2.4em;
    }
    &_xxl {
      margin-bottom: 2.8em;
    }
  }
}

.c-pdt {
  &0 {
    padding-top: 0;
  }
}

.c-pdl {
  &0 {
    padding-left: 0;
  }
}

.c-pdr {
  &0 {
    padding-right: 0;
  }
}

.c-pdb {
  &0 {
    padding-bottom: 0;
  }
}
