@charset "utf-8";
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tqj965');
  src:  url('../fonts/icomoon.eot?tqj965#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tqj965') format('truetype'),
    url('../fonts/icomoon.woff?tqj965') format('woff'),
    url('../fonts/icomoon.svg?tqj965#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon($type){
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $type == 'external-link' {
    content: "\e900";
  }
  @else if $type == 'arrow-right' {
    content: "\e901";
  }

}
