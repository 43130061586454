@charset "UTF-8";
/*--------------------------------------------
  setting
--------------------------------------------*/
/*--------------------------------------------
  value
--------------------------------------------*/
/*--------------------------------------------
  MIXIN 等
--------------------------------------------*/
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
[data-duration="50"] {
  transition-duration: 50ms; }

[data-duration="100"] {
  transition-duration: 100ms; }

[data-duration="150"] {
  transition-duration: 150ms; }

[data-duration="200"] {
  transition-duration: 200ms; }

[data-duration="250"] {
  transition-duration: 250ms; }

[data-duration="300"] {
  transition-duration: 300ms; }

[data-duration="350"] {
  transition-duration: 350ms; }

[data-duration="400"] {
  transition-duration: 400ms; }

[data-duration="450"] {
  transition-duration: 450ms; }

[data-duration="500"] {
  transition-duration: 500ms; }

[data-duration="550"] {
  transition-duration: 550ms; }

[data-duration="600"] {
  transition-duration: 600ms; }

[data-duration="650"] {
  transition-duration: 650ms; }

[data-duration="700"] {
  transition-duration: 700ms; }

[data-duration="750"] {
  transition-duration: 750ms; }

[data-duration="800"] {
  transition-duration: 800ms; }

[data-duration="850"] {
  transition-duration: 850ms; }

[data-duration="900"] {
  transition-duration: 900ms; }

[data-duration="950"] {
  transition-duration: 950ms; }

[data-duration="1000"] {
  transition-duration: 1000ms; }

[data-duration="1050"] {
  transition-duration: 1050ms; }

[data-duration="1100"] {
  transition-duration: 1100ms; }

[data-duration="1150"] {
  transition-duration: 1150ms; }

[data-duration="1200"] {
  transition-duration: 1200ms; }

[data-duration="1250"] {
  transition-duration: 1250ms; }

[data-duration="1300"] {
  transition-duration: 1300ms; }

[data-duration="1350"] {
  transition-duration: 1350ms; }

[data-duration="1400"] {
  transition-duration: 1400ms; }

[data-duration="1450"] {
  transition-duration: 1450ms; }

[data-duration="1500"] {
  transition-duration: 1500ms; }

[data-duration="1550"] {
  transition-duration: 1550ms; }

[data-duration="1600"] {
  transition-duration: 1600ms; }

[data-duration="1650"] {
  transition-duration: 1650ms; }

[data-duration="1700"] {
  transition-duration: 1700ms; }

[data-duration="1750"] {
  transition-duration: 1750ms; }

[data-duration="1800"] {
  transition-duration: 1800ms; }

[data-duration="1850"] {
  transition-duration: 1850ms; }

[data-duration="1900"] {
  transition-duration: 1900ms; }

[data-duration="1950"] {
  transition-duration: 1950ms; }

[data-duration="2000"] {
  transition-duration: 2000ms; }

[data-duration="2050"] {
  transition-duration: 2050ms; }

[data-duration="2100"] {
  transition-duration: 2100ms; }

[data-duration="2150"] {
  transition-duration: 2150ms; }

[data-duration="2200"] {
  transition-duration: 2200ms; }

[data-duration="2250"] {
  transition-duration: 2250ms; }

[data-duration="2300"] {
  transition-duration: 2300ms; }

[data-duration="2350"] {
  transition-duration: 2350ms; }

[data-duration="2400"] {
  transition-duration: 2400ms; }

[data-duration="2450"] {
  transition-duration: 2450ms; }

[data-duration="2500"] {
  transition-duration: 2500ms; }

[data-duration="2550"] {
  transition-duration: 2550ms; }

[data-duration="2600"] {
  transition-duration: 2600ms; }

[data-duration="2650"] {
  transition-duration: 2650ms; }

[data-duration="2700"] {
  transition-duration: 2700ms; }

[data-duration="2750"] {
  transition-duration: 2750ms; }

[data-duration="2800"] {
  transition-duration: 2800ms; }

[data-duration="2850"] {
  transition-duration: 2850ms; }

[data-duration="2900"] {
  transition-duration: 2900ms; }

[data-duration="2950"] {
  transition-duration: 2950ms; }

[data-duration="3000"] {
  transition-duration: 3000ms; }

[data-duration="3050"] {
  transition-duration: 3050ms; }

[data-duration="3100"] {
  transition-duration: 3100ms; }

[data-duration="3150"] {
  transition-duration: 3150ms; }

[data-duration="3200"] {
  transition-duration: 3200ms; }

[data-duration="3250"] {
  transition-duration: 3250ms; }

[data-duration="3300"] {
  transition-duration: 3300ms; }

[data-duration="3350"] {
  transition-duration: 3350ms; }

[data-duration="3400"] {
  transition-duration: 3400ms; }

[data-duration="3450"] {
  transition-duration: 3450ms; }

[data-duration="3500"] {
  transition-duration: 3500ms; }

[data-duration="3550"] {
  transition-duration: 3550ms; }

[data-duration="3600"] {
  transition-duration: 3600ms; }

[data-duration="3650"] {
  transition-duration: 3650ms; }

[data-duration="3700"] {
  transition-duration: 3700ms; }

[data-duration="3750"] {
  transition-duration: 3750ms; }

[data-duration="3800"] {
  transition-duration: 3800ms; }

[data-duration="3850"] {
  transition-duration: 3850ms; }

[data-duration="3900"] {
  transition-duration: 3900ms; }

[data-duration="3950"] {
  transition-duration: 3950ms; }

[data-duration="4000"] {
  transition-duration: 4000ms; }

[data-duration="4050"] {
  transition-duration: 4050ms; }

[data-duration="4100"] {
  transition-duration: 4100ms; }

[data-duration="4150"] {
  transition-duration: 4150ms; }

[data-duration="4200"] {
  transition-duration: 4200ms; }

[data-duration="4250"] {
  transition-duration: 4250ms; }

[data-duration="4300"] {
  transition-duration: 4300ms; }

[data-duration="4350"] {
  transition-duration: 4350ms; }

[data-duration="4400"] {
  transition-duration: 4400ms; }

[data-duration="4450"] {
  transition-duration: 4450ms; }

[data-duration="4500"] {
  transition-duration: 4500ms; }

[data-duration="4550"] {
  transition-duration: 4550ms; }

[data-duration="4600"] {
  transition-duration: 4600ms; }

[data-duration="4650"] {
  transition-duration: 4650ms; }

[data-duration="4700"] {
  transition-duration: 4700ms; }

[data-duration="4750"] {
  transition-duration: 4750ms; }

[data-duration="4800"] {
  transition-duration: 4800ms; }

[data-duration="4850"] {
  transition-duration: 4850ms; }

[data-duration="4900"] {
  transition-duration: 4900ms; }

[data-duration="4950"] {
  transition-duration: 4950ms; }

[data-duration="5000"] {
  transition-duration: 5000ms; }

[data-delay="50"] {
  transition-delay: 50ms; }

[data-delay="100"] {
  transition-delay: 100ms; }

[data-delay="150"] {
  transition-delay: 150ms; }

[data-delay="200"] {
  transition-delay: 200ms; }

[data-delay="250"] {
  transition-delay: 250ms; }

[data-delay="300"] {
  transition-delay: 300ms; }

[data-delay="350"] {
  transition-delay: 350ms; }

[data-delay="400"] {
  transition-delay: 400ms; }

[data-delay="450"] {
  transition-delay: 450ms; }

[data-delay="500"] {
  transition-delay: 500ms; }

[data-delay="550"] {
  transition-delay: 550ms; }

[data-delay="600"] {
  transition-delay: 600ms; }

[data-delay="650"] {
  transition-delay: 650ms; }

[data-delay="700"] {
  transition-delay: 700ms; }

[data-delay="750"] {
  transition-delay: 750ms; }

[data-delay="800"] {
  transition-delay: 800ms; }

[data-delay="850"] {
  transition-delay: 850ms; }

[data-delay="900"] {
  transition-delay: 900ms; }

[data-delay="950"] {
  transition-delay: 950ms; }

[data-delay="1000"] {
  transition-delay: 1000ms; }

[data-delay="1050"] {
  transition-delay: 1050ms; }

[data-delay="1100"] {
  transition-delay: 1100ms; }

[data-delay="1150"] {
  transition-delay: 1150ms; }

[data-delay="1200"] {
  transition-delay: 1200ms; }

[data-delay="1250"] {
  transition-delay: 1250ms; }

[data-delay="1300"] {
  transition-delay: 1300ms; }

[data-delay="1350"] {
  transition-delay: 1350ms; }

[data-delay="1400"] {
  transition-delay: 1400ms; }

[data-delay="1450"] {
  transition-delay: 1450ms; }

[data-delay="1500"] {
  transition-delay: 1500ms; }

[data-delay="1550"] {
  transition-delay: 1550ms; }

[data-delay="1600"] {
  transition-delay: 1600ms; }

[data-delay="1650"] {
  transition-delay: 1650ms; }

[data-delay="1700"] {
  transition-delay: 1700ms; }

[data-delay="1750"] {
  transition-delay: 1750ms; }

[data-delay="1800"] {
  transition-delay: 1800ms; }

[data-delay="1850"] {
  transition-delay: 1850ms; }

[data-delay="1900"] {
  transition-delay: 1900ms; }

[data-delay="1950"] {
  transition-delay: 1950ms; }

[data-delay="2000"] {
  transition-delay: 2000ms; }

[data-delay="2050"] {
  transition-delay: 2050ms; }

[data-delay="2100"] {
  transition-delay: 2100ms; }

[data-delay="2150"] {
  transition-delay: 2150ms; }

[data-delay="2200"] {
  transition-delay: 2200ms; }

[data-delay="2250"] {
  transition-delay: 2250ms; }

[data-delay="2300"] {
  transition-delay: 2300ms; }

[data-delay="2350"] {
  transition-delay: 2350ms; }

[data-delay="2400"] {
  transition-delay: 2400ms; }

[data-delay="2450"] {
  transition-delay: 2450ms; }

[data-delay="2500"] {
  transition-delay: 2500ms; }

[data-delay="2550"] {
  transition-delay: 2550ms; }

[data-delay="2600"] {
  transition-delay: 2600ms; }

[data-delay="2650"] {
  transition-delay: 2650ms; }

[data-delay="2700"] {
  transition-delay: 2700ms; }

[data-delay="2750"] {
  transition-delay: 2750ms; }

[data-delay="2800"] {
  transition-delay: 2800ms; }

[data-delay="2850"] {
  transition-delay: 2850ms; }

[data-delay="2900"] {
  transition-delay: 2900ms; }

[data-delay="2950"] {
  transition-delay: 2950ms; }

[data-delay="3000"] {
  transition-delay: 3000ms; }

[data-delay="3050"] {
  transition-delay: 3050ms; }

[data-delay="3100"] {
  transition-delay: 3100ms; }

[data-delay="3150"] {
  transition-delay: 3150ms; }

[data-delay="3200"] {
  transition-delay: 3200ms; }

[data-delay="3250"] {
  transition-delay: 3250ms; }

[data-delay="3300"] {
  transition-delay: 3300ms; }

[data-delay="3350"] {
  transition-delay: 3350ms; }

[data-delay="3400"] {
  transition-delay: 3400ms; }

[data-delay="3450"] {
  transition-delay: 3450ms; }

[data-delay="3500"] {
  transition-delay: 3500ms; }

[data-delay="3550"] {
  transition-delay: 3550ms; }

[data-delay="3600"] {
  transition-delay: 3600ms; }

[data-delay="3650"] {
  transition-delay: 3650ms; }

[data-delay="3700"] {
  transition-delay: 3700ms; }

[data-delay="3750"] {
  transition-delay: 3750ms; }

[data-delay="3800"] {
  transition-delay: 3800ms; }

[data-delay="3850"] {
  transition-delay: 3850ms; }

[data-delay="3900"] {
  transition-delay: 3900ms; }

[data-delay="3950"] {
  transition-delay: 3950ms; }

[data-delay="4000"] {
  transition-delay: 4000ms; }

[data-delay="4050"] {
  transition-delay: 4050ms; }

[data-delay="4100"] {
  transition-delay: 4100ms; }

[data-delay="4150"] {
  transition-delay: 4150ms; }

[data-delay="4200"] {
  transition-delay: 4200ms; }

[data-delay="4250"] {
  transition-delay: 4250ms; }

[data-delay="4300"] {
  transition-delay: 4300ms; }

[data-delay="4350"] {
  transition-delay: 4350ms; }

[data-delay="4400"] {
  transition-delay: 4400ms; }

[data-delay="4450"] {
  transition-delay: 4450ms; }

[data-delay="4500"] {
  transition-delay: 4500ms; }

[data-delay="4550"] {
  transition-delay: 4550ms; }

[data-delay="4600"] {
  transition-delay: 4600ms; }

[data-delay="4650"] {
  transition-delay: 4650ms; }

[data-delay="4700"] {
  transition-delay: 4700ms; }

[data-delay="4750"] {
  transition-delay: 4750ms; }

[data-delay="4800"] {
  transition-delay: 4800ms; }

[data-delay="4850"] {
  transition-delay: 4850ms; }

[data-delay="4900"] {
  transition-delay: 4900ms; }

[data-delay="4950"] {
  transition-delay: 4950ms; }

[data-delay="5000"] {
  transition-delay: 5000ms; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

img {
  vertical-align: top; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

ul li,
ol li {
  list-style-type: none; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1; }

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media screen and (max-width: 1199px) {
    html {
      font-size: .9vw; } }
  @media screen and (max-width: 991px) {
    html {
      font-size: 1.3vw; } }
  @media screen and (max-width: 767px) {
    html {
      font-size: 1.5vw; } }
  @media screen and (max-width: 575px) {
    html {
      font-size: 2.5vw; } }

body {
  font-family: "Hiragino Kaku Gothic Pro", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", "Yu Gothic Medium", "Yu Gothic", Meiryo, Verdana, arial, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #0D0B01;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased;
  position: relative; }
  @media screen and (max-width: 767px) {
    body {
      line-height: 1.6; } }

.grecaptcha-badge {
  z-index: 50; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

main {
  display: block; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
select,
textarea {
  width: 100%;
  font-size: 1.6rem;
  border: solid 1px #cccccc;
  line-height: 1.3;
  display: inline-block;
  padding: .5em .65em; }
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  input[type="url"]:focus,
  input[type="search"]:focus,
  select:focus,
  textarea:focus {
    outline: none; }
  @media screen and (max-width: 1199px) {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="url"],
    input[type="search"],
    select,
    textarea {
      padding: .75em 1em; } }

::placeholder {
  color: #cccccc; }

select {
  padding: .75em 1.5em .75em .75em; }
  .ios select {
    -webkit-appearance: button;
    appearance: button; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea {
  line-height: 1;
  vertical-align: middle; }
  input[type="text"]:fucus,
  input[type="number"]:fucus,
  input[type="password"]:fucus,
  input[type="email"]:fucus,
  input[type="tel"]:fucus,
  input[type="url"]:fucus,
  input[type="search"]:fucus,
  textarea:fucus {
    outline: none;
    background: #d4ebf7; }

textarea {
  min-height: 12.5em; }
  @media screen and (max-width: 767px) {
    textarea {
      min-height: 10em; } }

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  color: #0D0B01;
  border: none;
  background: none; }
  input[type="button"]:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  button:focus {
    outline: none; }

img {
  vertical-align: middle; }

table {
  width: 100%; }

a {
  color: #0D0B01;
  text-decoration: none; }
  a[href=""] {
    pointer-events: none; }
  @media screen and (min-width: 992px) {
    a {
      transition: all 0.6s; } }

svg {
  width: 1em;
  height: 1em;
  fill: #0D0B01; }

h1, h2, h3, h4, h5, h6, th, dt, strong, b {
  font-weight: 500;
  line-height: 1; }

@-ms-viewport {
  width: device-width; }

[role="button"] {
  cursor: pointer; }

.hidden {
  display: none !important; }

.show {
  display: block !important; }

.visible-ss,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-ss-i,
.visible-xs-i,
.visible-sm-i,
.visible-md-i,
.visible-lg-i {
  display: none !important; }

.visible-ss-ib,
.visible-xs-ib,
.visible-sm-ib,
.visible-md-ib,
.visible-lg-ib {
  display: none !important; }

@media screen and (max-width: 575px) {
  .visible-ss {
    display: block !important; }
  table.visible-ss {
    display: table !important; }
  tr.visible-ss {
    display: table-row !important; }
  th.visible-ss,
  td.visible-ss {
    display: table-cell !important; }
  .visible-ss-i {
    display: inline !important; }
  .visible-ss-ib {
    display: inline-block !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; }
  .visible-xs-i {
    display: inline !important; }
  .visible-xs-ib {
    display: inline-block !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  .visible-sm-i {
    display: inline !important; }
  .visible-sm-ib {
    display: inline-block !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; }
  .visible-md-i {
    display: inline !important; }
  .visible-md-ib {
    display: inline-block !important; } }

@media screen and (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; }
  .visible-lg-i {
    display: inline !important; }
  .visible-lg-ib {
    display: inline-block !important; } }

@media screen and (max-width: 575px) {
  .hidden-ss {
    display: none !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-sp {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .visible-tb {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .visible-pc {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-sp {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important; } }

/*--------------------------------------------
  plugins
--------------------------------------------*/
/*
SP MENU SAMPLE - HTML BTN + JS
------------------------------

<nav class="slideout-menu" id="js-so_menu">
  <div class="menu-inner">
  </div>
</nav>

<div class="slideout-panel" id="js-so_panel"></div>

<header></header>


<button type="button" class="slideout-hamburger" id="js-so-toggle">
  <span class="sr-only">MENU</span>
  <span class="hamburger-icon"></span>
</button>


// slideout JS
var slideout = new Slideout({
  'panel': document.getElementById('js-so_panel'),
  'menu': document.getElementById('js-so_menu'),
  'duration': 10,
});
var nodeList = document.querySelectorAll('#js-so-toggle, #js-so_panel');
var node = Array.prototype.slice.call(nodeList,0);
node.forEach(function(elem, index){
  elem.addEventListener('click', function() {
    slideout.toggle();
  });
});
*/
.slideout-open,
.slideout-open body {
  overflow: hidden; }

.slideout-menu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 101;
  width: 100%;
  background-color: #333;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1); }

.slideout-open .slideout-menu {
  right: 0; }

.slideout-menu .menu-inner {
  padding: 6em 1.5em; }

.slideout-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  will-change: transform;
  transform: translateX(0) !important;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none; }

.slideout-open .slideout-panel {
  z-index: 100;
  opacity: 1;
  pointer-events: auto; }

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.slideout-hamburger {
  display: block;
  width: 4.5em;
  height: 100%;
  padding: 2em 1em;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #222;
  cursor: pointer; }

.hamburger-icon {
  position: relative;
  display: block; }

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 100%;
  height: .2rem;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #fff; }

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  position: absolute;
  top: -.6em;
  left: 0; }

.hamburger-icon::after {
  top: .6em; }

.slideout-open .hamburger-icon {
  background-color: transparent; }

.slideout-open .hamburger-icon::before,
.slideout-open .hamburger-icon::after {
  top: 0; }

.slideout-open .hamburger-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.slideout-open .hamburger-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/plugins/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*--------------------------------------------
  module
--------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?tqj965");
  src: url("../fonts/icomoon.eot?tqj965#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?tqj965") format("truetype"), url("../fonts/icomoon.woff?tqj965") format("woff"), url("../fonts/icomoon.svg?tqj965#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 1200px;
  max-width: 92%;
  margin-left: auto;
  margin-right: auto; }
  .c-inner.-full {
    width: 92%; }
  @media screen and (max-width: 575px) {
    .c-inner {
      max-width: 90%; } }

.c-fullpage {
  width: 100%;
  height: 100vh; }
  .c-fullpage__sec {
    width: 100%;
    min-height: 100vh;
    filter: blur(2rem);
    transition: filter 0.6s 0.6s; }
    .c-fullpage__sec .c-inner {
      padding: 16rem 0;
      max-width: 80%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column; }
      @media screen and (max-width: 575px) {
        .c-fullpage__sec .c-inner {
          max-width: 90%;
          margin-right: 10%;
          padding: 16rem 2rem; } }
    .c-fullpage__sec.is-show {
      filter: blur(0); }
  .c-fullpage__pager {
    position: fixed;
    right: 4rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9; }
    .c-fullpage__pager li {
      position: relative; }
      .c-fullpage__pager li:nth-child(n + 2) {
        padding-top: 4rem; }
        .c-fullpage__pager li:nth-child(n + 2):before {
          position: absolute;
          content: "";
          left: 0.3125em;
          top: 0.5rem;
          width: 1px;
          height: 3rem;
          background: #fff; }
      .c-fullpage__pager li a {
        display: block;
        width: calc(0.625em + 1px);
        height: calc(0.625em + 1px);
        border: solid 2px #fff;
        border-radius: 50%; }
      .c-fullpage__pager li.is-act a {
        background: #fff; }
  @media screen and (max-width: 1599px) {
    .c-fullpage__pager {
      right: 2rem; } }

.c-stripe {
  background: url("../img/common/c-stripe.jpg") repeat; }

.c-bg_color {
  background: #FFFBE4; }

.c-img_responsive {
  display: block;
  max-width: 100%;
  height: auto; }

@media screen and (min-width: 992px) {
  .c-boxpd {
    padding-top: 6.25em;
    padding-bottom: 6.25em; }
    .c-boxpd_sm {
      padding-top: 5em;
      padding-bottom: 5em; }
    .c-boxpd_lg {
      padding-top: 9.4em;
      padding-bottom: 9.4em; } }

@media screen and (max-width: 991px) {
  .c-boxpd {
    padding-top: 4em;
    padding-bottom: 4em; }
    .c-boxpd_sm {
      padding-top: 3em;
      padding-bottom: 3em; }
    .c-boxpd_lg {
      padding-top: 6em;
      padding-bottom: 6em; } }

/*-------------------------------
  under > key
-------------------------------*/
.p-under_key {
  padding: 5em 0;
  text-align: center;
  line-height: 1; }
  .p-under_key .en {
    font-size: 6rem; }
  .p-under_key h2 {
    font-size: 2.5rem; }
  @media screen and (max-width: 767px) {
    .p-under_key {
      padding: 3em 0; }
      .p-under_key .en {
        font-size: 4rem; }
      .p-under_key h2 {
        font-size: 2.2rem; } }

/*--------------------------------------------
  TO TOP
--------------------------------------------*/
.totop {
  position: absolute;
  top: -11.5rem;
  right: 5rem;
  width: 8.1rem;
  height: 9.5rem;
  animation: totop 3s linear 0.5s infinite; }
  .totop > a {
    display: block; }
  @media screen and (max-width: 991px) {
    .totop {
      right: 2rem;
      top: -8rem; } }
  @media screen and (max-width: 767px) {
    .totop {
      top: -7rem; } }

@keyframes totop {
  0% {
    transform: translateY(0); }
  10% {
    transform: translateY(-1rem); }
  20% {
    transform: translateY(0); }
  25% {
    transform: translateY(-0.3rem); }
  30% {
    transform: translateY(0); }
  50% {
    transform: translateY(0); }
  75% {
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-ttl01 {
  line-height: 1;
  text-align: center;
  margin-bottom: 4rem;
  transform: translateY(6rem);
  opacity: 0;
  transition-duration: 0.6s; }
  .c-ttl01 span {
    font-family: "Passion One", cursive;
    font-size: 7.2rem;
    position: relative;
    display: inline-block;
    margin-bottom: 1.5rem; }
    .c-ttl01 span:before, .c-ttl01 span:after {
      content: "";
      display: block;
      position: absolute; }
    .c-ttl01 span:before {
      background: url("../img/common/c-ttl_l.png") no-repeat;
      width: 61px;
      height: 56px;
      left: -1.1em;
      top: 0;
      animation: t-deco 2s linear 0s infinite; }
    .c-ttl01 span:after {
      background: url("../img/common/c-ttl_r.png") no-repeat;
      width: 80px;
      height: 65px;
      right: -1.2em;
      top: 0;
      animation: tail 3s linear 0.5s infinite; }
  .c-ttl01 h2 {
    font-size: 3rem; }

@keyframes t-deco {
  0% {
    transform: scale(1); }
  15% {
    transform: scale(0.9); }
  25% {
    transform: scale(1); }
  50% {
    transform: scale(1); }
  75% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@keyframes tail {
  0% {
    transform: rotate(0);
    transform-origin: 0 50%; }
  10% {
    transform: rotate(20deg);
    transform-origin: 0 50%; }
  20% {
    transform: rotate(0);
    transform-origin: 0 50%; }
  25% {
    transform: rotate(10deg);
    transform-origin: 0 50%; }
  30% {
    transform: rotate(0);
    transform-origin: 0 50%; }
  50% {
    transform: rotate(0);
    transform-origin: 0 50%; }
  75% {
    transform: rotate(0);
    transform-origin: 0 50%; }
  100% {
    transform: rotate(0);
    transform-origin: 0 50%; } }
  @media screen and (max-width: 991px) {
    .c-ttl01 {
      margin-bottom: 3rem; }
      .c-ttl01.-imgtxt span {
        font-size: 1rem;
        margin-bottom: 2.5em; }
      .c-ttl01.-imgtxt img {
        width: 70%; }
      .c-ttl01 span {
        font-size: 5rem; }
        .c-ttl01 span:before, .c-ttl01 span:after {
          background-size: contain; }
        .c-ttl01 span:before {
          width: 44px;
          height: 40px;
          left: -1em; }
        .c-ttl01 span:after {
          width: 54px;
          height: 45px;
          right: -1.2em; }
      .c-ttl01 h2 {
        font-size: 2rem; } }
  @media screen and (max-width: 767px) {
    .c-ttl01 span {
      font-size: 4rem;
      margin-bottom: 0.3em; }
      .c-ttl01 span:before {
        width: 32px;
        height: 30px; }
      .c-ttl01 span:after {
        width: 43px;
        height: 36px;
        right: -1.4em; }
    .c-ttl01 h2 {
      font-size: 1.8rem; } }

.is-anime .c-ttl01 {
  transform: translateY(0rem);
  opacity: 1; }

.c-ttl02 {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 3rem; }
  .c-ttl02 span {
    display: block;
    font-size: 0.6em;
    margin-top: 0.5em; }
  @media screen and (max-width: 991px) {
    .c-ttl02 {
      font-size: 3rem; } }
  @media screen and (max-width: 767px) {
    .c-ttl02 {
      font-size: 2.3rem;
      line-height: 1.3;
      margin-bottom: 2rem; } }

.c-ttl03 {
  position: relative;
  margin-bottom: 1.6em;
  padding: 0.6em;
  padding-left: 3em;
  background-color: #FBD70A;
  border-radius: 0.5em;
  border-left: 2.3rem solid #0D0B01;
  font-size: 3rem;
  line-height: 1.3; }
  .c-ttl03::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0.6em;
    bottom: 0;
    margin: auto;
    width: 4.9rem;
    height: 3.7rem;
    background: url("../img/common/pad.png") center/contain no-repeat; }
  @media screen and (max-width: 991px) {
    .c-ttl03 {
      margin-bottom: 1.3em;
      padding: 0.6em;
      padding-left: 2.5em;
      border-left-width: 1.5rem;
      font-size: 2.4rem; }
      .c-ttl03::before {
        width: 3.5rem;
        height: 3rem; } }
  @media screen and (max-width: 767px) {
    .c-ttl03 {
      border-left-width: 1.2rem;
      font-size: 2.2rem; }
      .c-ttl03::before {
        width: 3.2rem;
        height: 2.6rem; } }

.c-ttl04 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5em; }
  .c-ttl04 > .num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25em;
    height: 1.25em;
    margin-bottom: 0.3em;
    background-color: #FBD70A;
    border-radius: 50%;
    font-weight: 400;
    font-family: "Passion One", cursive;
    font-size: 2.4rem;
    line-height: 1; }
  .c-ttl04 > .main {
    padding-bottom: 0.2em;
    border-bottom: 4px solid #0D0B01;
    font-size: 3rem;
    line-height: 1.3; }
  @media screen and (max-width: 991px) {
    .c-ttl04 {
      margin-bottom: 1.5em; }
      .c-ttl04 > .num {
        font-size: 2rem; }
      .c-ttl04 > .main {
        border-width: 2px;
        font-size: 2.4rem; } }
  @media screen and (max-width: 767px) {
    .c-ttl04 > .num {
      font-size: 1.8rem; }
    .c-ttl04 > .main {
      font-size: 2.2rem; } }

.en {
  font-family: "Passion One", cursive; }

.c-lead {
  font-size: 1.5em;
  margin-bottom: 2.5em;
  font-weight: 400; }
  @media screen and (max-width: 1199px) {
    .c-lead {
      font-size: 1.35em;
      margin-bottom: 2em; } }
  @media screen and (max-width: 575px) {
    .c-lead {
      line-height: 1.4; } }

/*--------------------------------------------
  DL
--------------------------------------------*/
.c-dl_label {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6em;
  margin-left: -1.6em; }
  .c-dl_label__item {
    display: flex;
    align-items: center;
    width: calc((100% - 3.2em) / 2);
    margin-top: 1.6em;
    margin-left: 1.6em;
    padding: 1.3em;
    background-color: #FFFBE4;
    border-radius: 0.5em; }
    .c-dl_label__item > dt {
      width: 11rem;
      padding: 0.4em 0.2em;
      background-color: #FBD70A;
      border-radius: 0.4em;
      text-align: center;
      font-size: 2.2rem;
      line-height: 1.1; }
    .c-dl_label__item > dd {
      width: calc(100% - 11rem);
      padding-left: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5; }
  @media screen and (max-width: 991px) {
    .c-dl_label {
      margin-top: 2%;
      margin-left: -2%; }
      .c-dl_label__item {
        width: calc((100% - 4%) / 2);
        margin-top: 2%;
        margin-left: 2%;
        padding: 1em; }
        .c-dl_label__item > dt {
          width: 9rem;
          font-size: 1.8rem; }
        .c-dl_label__item > dd {
          width: calc(100% - 9rem);
          padding-left: 1em;
          font-size: 1.6rem; } }
  @media screen and (max-width: 767px) {
    .c-dl_label__item {
      width: 100%; } }

/*--------------------------------------------
  COLUMN
--------------------------------------------*/
.c-col {
  display: flex;
  flex-wrap: wrap;
  margin: -5em -2.5em 0; }
  .c-col__item {
    width: 50%;
    margin-top: 5em;
    padding: 0 2.5em; }
  .c-col.-gallery {
    margin: -0.8em -0.4em 0; }
    .c-col.-gallery .c-col__item {
      margin-top: 0.8em;
      padding: 0 0.4em; }
  .c-col.-gallery_col3 {
    margin: -1.2em -0.6em 0; }
    .c-col.-gallery_col3 .c-col__item {
      width: 33.3333%;
      margin-top: 1.2em;
      padding: 0 0.6em; }
  @media screen and (max-width: 991px) {
    .c-col {
      margin: -2em -1.5% 0; }
      .c-col__item {
        margin-top: 2em;
        padding: 0 1.5%; }
      .c-col.-gallery {
        margin: -0.4em -0.2em 0; }
        .c-col.-gallery .c-col__item {
          margin-top: 0.4em;
          padding: 0 0.2em; }
      .c-col.-gallery_col3 {
        margin: -0.8em -0.4em 0; }
        .c-col.-gallery_col3 .c-col__item {
          margin-top: 0.8em;
          padding: 0 0.4em; } }
  @media screen and (max-width: 575px) {
    .c-col__item {
      width: 100%; }
    .c-col.-gallery .c-col__item {
      width: 50%; }
    .c-col.-gallery_col3 .c-col__item {
      width: 50%; } }

/*--------------------------------------------
  LIST
--------------------------------------------*/
.c-list_label {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.2rem;
  margin-left: -1.2rem; }
  .c-list_label__list {
    margin-top: 1.2rem;
    margin-left: 1.2rem;
    padding: 0.4em 0.8em;
    background-color: #FFFBE4;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.1; }
  @media screen and (max-width: 991px) {
    .c-list_label {
      margin-top: -1rem;
      margin-left: -1rem; }
      .c-list_label__list {
        margin-top: 1rem;
        margin-left: 1rem;
        font-size: 2rem; } }
  @media screen and (max-width: 767px) {
    .c-list_label__list {
      font-size: 1.8rem; } }

.c-list_dot__list {
  position: relative;
  padding-left: 1.6em;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.3; }
  .c-list_dot__list::before {
    content: "";
    display: block;
    position: absolute;
    top: 0.3em;
    left: 0.4em;
    width: 0.7em;
    height: 0.7em;
    background-color: #FBD70A;
    border-radius: 50%; }
  .c-list_dot__list + .c-list_dot__list {
    margin-top: 0.4em; }

@media screen and (max-width: 991px) {
  .c-list_dot__list {
    font-size: 2rem; } }

@media screen and (max-width: 767px) {
  .c-list_dot__list {
    font-size: 1.8rem; } }

/*--------------------------------------------
  TAB
--------------------------------------------*/
.c-tab.c-flex {
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto 8rem; }
  .c-tab.c-flex li {
    width: 48%; }
    .c-tab.c-flex li a {
      background: #0D0B01;
      display: block;
      color: #fff;
      border-radius: 10px;
      text-align: center;
      line-height: 1;
      padding: 2em 1em;
      border: 2px solid #0D0B01;
      font-size: 2rem;
      font-weight: 600; }
      @media screen and (min-width: 992px) {
        .c-tab.c-flex li a:hover {
          background: #fff;
          color: #0D0B01; } }
  @media screen and (max-width: 767px) {
    .c-tab.c-flex {
      margin: 0 auto 4rem; }
      .c-tab.c-flex li a {
        padding: 1em; } }

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap.-fx {
  display: flex;
  justify-content: center;
  align-items: center; }
  .c-btnwrap.-fx.-justify {
    justify-content: space-between; }
  .c-btnwrap.-fx.-right {
    justify-content: flex-end; }

.c-btnwrap.-center {
  text-align: center; }

.c-btnwrap.-right {
  text-align: right; }

.c-btn01 a {
  display: block;
  background: #FBD70A;
  border-radius: 10px;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem;
  border: 2px solid #FBD70A; }
  .c-btn01 a span {
    position: relative;
    padding-left: 2em; }
    .c-btn01 a span:before {
      content: "";
      background: url("../img/common/icon_arrow02.png") no-repeat;
      width: 29px;
      height: 38px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .c-btn01 a:hover {
    background: #0D0B01;
    color: #FBD70A; }

.c-btn02 {
  max-width: 350px;
  margin: auto;
  line-height: 1; }
  .c-btn02 a {
    display: block;
    background: #194C97;
    color: #fff;
    border-radius: 10px;
    padding: 2em 1em;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    border: 2px solid #194C97; }
    @media screen and (min-width: 992px) {
      .c-btn02 a:hover {
        background: #fff;
        color: #194C97; } }
    .c-btn02 a.-bl {
      background: #0D0B01;
      border: 2px solid #0D0B01; }
      @media screen and (min-width: 992px) {
        .c-btn02 a.-bl:hover {
          background: #fff;
          color: #0D0B01; } }
  @media screen and (max-width: 767px) {
    .c-btn02 {
      width: 92%; }
      .c-btn02 a {
        padding: 1.5em; } }

.c-btn03 a {
  display: block;
  max-width: 50rem;
  background: #FBD70A;
  border-radius: 10px;
  text-align: center;
  font-size: 3.4rem;
  font-weight: 600;
  padding: 2rem;
  border: 2px solid #FBD70A; }
  .c-btn03 a span {
    display: inline-block;
    position: relative;
    padding: 0.8em;
    padding-right: 2em; }
    .c-btn03 a span:before {
      content: "";
      background: url("../img/common/icon_arrow03.png") center/contain no-repeat;
      width: 4.7rem;
      height: 6.5rem;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
  .c-btn03 a:hover {
    background: #0D0B01;
    color: #FBD70A; }

@media screen and (max-width: 991px) {
  .c-btn03 a {
    max-width: 32rem;
    font-size: 2.4rem; }
    .c-btn03 a span {
      padding: 0.6em;
      padding-right: 2em; }
      .c-btn03 a span:before {
        width: 4rem;
        height: 6rem; } }

@media screen and (max-width: 575px) {
  .c-btn03 a {
    max-width: 26rem;
    padding: 1.5rem;
    font-size: 2.4rem; }
    .c-btn03 a span {
      padding: 0.4em;
      padding-right: 1.5em; }
      .c-btn03 a span:before {
        width: 3rem;
        height: 5rem; } }

/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tbl {
  margin-bottom: 5rem; }
  .c-tbl .input_middle input[type="text"] {
    width: 70%; }
  .c-tbl .input_short select {
    width: 40%; }
  .c-tbl input[type="radio"] {
    width: 1em;
    height: 1em; }
  .c-tbl .address .input_short {
    width: 50%; }
  .c-tbl .check {
    display: flex;
    max-width: 50rem; }
    .c-tbl .check .form_input {
      display: flex;
      align-items: center; }
      .c-tbl .check .form_input span {
        margin-left: 0.5em; }
    .c-tbl .check .year {
      width: 40%; }
    .c-tbl .check .month {
      width: 30%;
      margin-left: 1em; }
    .c-tbl .check .day {
      width: 30%;
      margin-left: 1em; }
  .c-tbl tr {
    border: 2px solid #fff; }
  .c-tbl th {
    padding: 2em 0.5em;
    background: #FBD70A;
    width: 25%;
    line-height: 1.6;
    font-size: 1.8rem; }
    .c-tbl th span {
      background: #194C97;
      display: inline-block;
      border-radius: 5px;
      color: #fff;
      font-size: 1.3rem;
      line-height: 1;
      padding: 0.5em;
      margin-left: 1em; }
  .c-tbl td {
    width: 75%;
    padding: 2em 2.5em; }
  @media screen and (max-width: 991px) {
    .c-tbl .check {
      width: 100%; }
    .c-tbl th {
      font-size: 1.6rem; } }
  @media screen and (max-width: 767px) {
    .c-tbl {
      margin-bottom: 1rem; }
      .c-tbl .input_middle input[type="text"] {
        width: 100%; }
      .c-tbl select {
        padding: 0.8em 0.3em; }
      .c-tbl .input_short select {
        width: 100%; }
      .c-tbl tr,
      .c-tbl th,
      .c-tbl td {
        display: block;
        width: 100%; }
      .c-tbl th {
        padding: 0.5em; }
      .c-tbl td {
        padding: 1em 0.5em; } }

.submit_area {
  text-align: center; }
  .submit_area input[type="submit"] {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    margin: auto;
    padding: 1em;
    transition-duration: 0.3s; }
  .submit_area .btn_submit {
    max-width: 30rem;
    width: 100%;
    border: 2px solid #194C97;
    background: #194C97;
    margin: auto; }
    @media screen and (min-width: 992px) {
      .submit_area .btn_submit:hover {
        color: #194C97;
        background: #fff; } }
  .submit_area .back {
    background: #777;
    max-width: 30rem;
    width: 100%;
    border: 2px solid #777;
    margin: auto; }
    @media screen and (min-width: 992px) {
      .submit_area .back:hover {
        color: #777;
        background: #fff; } }

@media screen and (max-width: 991px) {
  .c-tbl_responsive__inner {
    overflow-x: auto;
    overflow-y: visible; }
    .c-tbl_responsive__inner table {
      min-width: 100%;
      width: auto; }
      .c-tbl_responsive__inner table th,
      .c-tbl_responsive__inner table td {
        white-space: nowrap; }
    .c-tbl_responsive__inner::-webkit-scrollbar {
      height: 0.6rem; }
    .c-tbl_responsive__inner::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .c-tbl_responsive__inner::-webkit-scrollbar-thumb {
      background: #FBD70A; }
  .c-tbl_responsive:after {
    display: block;
    content: "横にスクロールできます";
    text-align: center;
    margin-top: 0.5em; } }

/*--------------------------------------------
 BLOCK - NEWS
--------------------------------------------*/
.c-news {
  padding: 8rem;
  position: relative; }
  .c-news .c-inner {
    max-width: 100%; }
  .c-news__list {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto 2em;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s; }
    .c-news__list li {
      width: 100%;
      background: #fff;
      border-radius: 10px;
      margin: auto; }
      .c-news__list li:nth-child(n + 2) {
        margin-top: 1em; }
      .c-news__list li a {
        padding: 2em 1.3em;
        flex-wrap: nowrap;
        align-items: center; }
        .c-news__list li a p {
          line-height: 1.4;
          position: relative; }
          .c-news__list li a p:before {
            content: "";
            background: #FBD70A;
            height: 4px;
            width: 0%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            transition-duration: 0.3s; }
        .c-news__list li a:hover p:before {
          width: 100%; }
      .c-news__list li .date {
        font-size: 3rem;
        line-height: 1;
        margin-right: 1em; }
      .c-news__list li .cate {
        font-size: 1.8rem;
        color: #fff;
        background: #0D0B01;
        line-height: 1;
        padding: 0.3em 0.8em;
        margin-right: 1em; }
  .c-news.is-anime .c-ttl01 {
    transition-delay: 1.3s; }
  .c-news.is-anime .c-news__list {
    transform: translateY(0rem);
    transition-delay: 1.5s;
    opacity: 1; }
  @media screen and (max-width: 991px) {
    .c-news {
      padding: 6rem 0; }
      .c-news__list {
        max-width: 92%; }
        .c-news__list li .date {
          font-size: 2.5rem; }
        .c-news__list li .cate {
          font-size: 1.6rem; } }
  @media screen and (max-width: 767px) {
    .c-news__list li a {
      flex-wrap: wrap; }
      .c-news__list li a .date {
        font-size: 2rem; }
      .c-news__list li a p {
        width: 100%;
        margin-top: 0.5em; } }

/*--------------------------------------------
 BLOCK - ENTRY
--------------------------------------------*/
.c-entry {
  padding: 15rem 0 0; }
  .c-entry__btn.c-flex {
    justify-content: center;
    position: relative;
    padding-top: 13rem; }
    .c-entry__btn.c-flex a {
      position: relative;
      display: block;
      border-radius: 15px;
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 1.4;
      padding: 1.8em 0.5em; }
    .c-entry__btn.c-flex div {
      transform: translateY(7rem);
      opacity: 0;
      transition-duration: 0.6s; }
    .c-entry__btn.c-flex .n-graduate {
      transition-delay: 0.5s; }
      .c-entry__btn.c-flex .n-graduate:before {
        background: url("../img/index/idx_entry_01.png") no-repeat;
        background-size: contain;
        width: 240px;
        height: 256px;
        transition-duration: 0.3s; }
      .c-entry__btn.c-flex .n-graduate:after {
        background: url("../img/common/icon_arrow_y.png") no-repeat;
        background-size: contain; }
      .c-entry__btn.c-flex .n-graduate a {
        background: #0D0B01;
        color: #fff; }
    .c-entry__btn.c-flex .part {
      transition-delay: 1s; }
      .c-entry__btn.c-flex .part:before {
        background: url("../img/index/idx_entry_02.png") no-repeat;
        background-size: contain;
        width: 217px;
        height: 254px;
        transition-duration: 0.3s; }
      .c-entry__btn.c-flex .part:after {
        background: url("../img/common/icon_arrow_bk.png") no-repeat;
        background-size: contain; }
      .c-entry__btn.c-flex .part a {
        background: #FBD70A; }
  .c-entry__item {
    width: 48%;
    text-align: center;
    position: relative; }
    .c-entry__item:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%); }
    .c-entry__item:after {
      content: "";
      width: 26px;
      height: 40px;
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      z-index: 3; }
  .c-entry__stop {
    transition-delay: 1.5s;
    margin-top: 1em;
    text-align: center;
    font-size: 2vw;
    font-weight: 700;
    line-height: 1.4;
    opacity: 0; }
  .c-entry.is-anime .c-entry__btn.c-flex div {
    transform: translateY(0rem);
    opacity: 1; }
  .c-entry.is-anime .c-entry__stop {
    opacity: 1; }
  @media screen and (min-width: 992px) {
    .c-entry__btn.c-flex .c-entry__item:hover:before {
      top: -10%; } }
  @media screen and (max-width: 991px) {
    .c-entry {
      padding: 10rem 0; }
      .c-entry__btn.c-flex .n-graduate:before {
        width: 190px;
        height: 215px; }
      .c-entry__btn.c-flex .part:before {
        width: 190px;
        height: 230px; }
      .c-entry__btn.c-flex a {
        font-size: 2.3vw; }
      .c-entry__item:after {
        width: 16px;
        height: 25px; }
      .c-entry__stop {
        font-size: 3vw; } }
  @media screen and (max-width: 767px) {
    .c-entry {
      padding: 8rem 0 10rem; }
      .c-entry__btn.c-flex {
        display: block;
        padding-top: 10rem; }
        .c-entry__btn.c-flex .n-graduate {
          margin-bottom: 12rem; }
          .c-entry__btn.c-flex .n-graduate:before {
            width: 160px;
            height: 180px; }
        .c-entry__btn.c-flex .part:before {
          width: 150px;
          height: 187px; }
        .c-entry__btn.c-flex a {
          font-size: 2rem; }
      .c-entry__item {
        width: 100%; }
      .c-entry__stop {
        font-size: 2.4rem; } }

/*--------------------------------------------
 BLOCK - FORM
--------------------------------------------*/
.c-form_box__sheet {
  margin-bottom: 8rem; }
  .c-form_box__sheet tr {
    border-bottom: solid 1px rgba(255, 255, 255, 0.4); }
    .c-form_box__sheet tr.-top th,
    .c-form_box__sheet tr.-top td {
      vertical-align: top; }
    .c-form_box__sheet tr.-top .c-form_box__label {
      padding-top: 0.5em; }
  .c-form_box__sheet th,
  .c-form_box__sheet td {
    padding: 2em;
    vertical-align: middle;
    text-align: left;
    line-height: 1.6;
    font-weight: 400; }
  .c-form_box__sheet th {
    width: 25%; }
  .c-form_box__sheet td {
    width: 85%; }
  @media screen and (max-width: 991px) {
    .c-form_box__sheet tr {
      display: block;
      padding: 1.75em 0; }
    .c-form_box__sheet th,
    .c-form_box__sheet td {
      display: block;
      padding: 0;
      width: 100%; }
    .c-form_box__sheet th {
      line-height: 1;
      margin-bottom: 1.5em; } }

.c-form_box__label {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  .c-form_box__label.-require:after {
    content: "【必須】";
    color: #0D0B01;
    margin-left: 0.5em; }

@media screen and (max-width: 991px) and (min-width: 768px) {
  .c-form_box__label.-require:after {
    display: block;
    font-size: 0.875em;
    margin-left: 0; } }

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .c-form_box__label {
    display: block; }
    .c-form_box__label.-require:after {
      display: block;
      margin-left: 0;
      text-indent: -0.5em; } }

.c-form_box .c-form_box__error {
  margin-top: 0.5em; }
  .c-form_box .c-form_box__error .error {
    color: #0D0B01;
    font-size: 1em; }
    .c-form_box .c-form_box__error .error:before {
      content: "※";
      margin-right: 0.25em; }

@media screen and (min-width: 992px) {
  .c-form_box__submit {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.c-form_box__btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse; }
  @media screen and (min-width: 768px) {
    .c-form_box__btn .c-btn_pl {
      width: 300px; }
      .c-form_box__btn .c-btn_pl:nth-child(n + 2) {
        margin-right: 2rem; } }
  @media screen and (max-width: 991px) {
    .c-form_box__btn {
      justify-content: center;
      margin-top: 3rem;
      flex-direction: row; } }
  @media screen and (max-width: 767px) {
    .c-form_box__btn {
      flex-direction: row; }
      .c-form_box__btn .c-btn_pl {
        display: block;
        margin: 0 auto;
        width: 48%; }
        .c-form_box__btn .c-btn_pl:nth-child(n + 2) {
          margin-left: 4%;
          margin-right: 0; } }
  @media screen and (max-width: 575px) {
    .c-form_box__btn {
      display: block; }
      .c-form_box__btn .c-btn_pl {
        width: 100%; }
        .c-form_box__btn .c-btn_pl:nth-child(n + 2) {
          margin-left: 0;
          margin-top: 3rem; } }

.c-form_box__agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .c-form_box__agreement a {
    text-decoration: underline; }
    @media screen and (min-width: 992px) {
      .c-form_box__agreement a:hover {
        text-decoration: none; } }
  .c-form_box__agreement .c-form_box__input.-check {
    padding: 0;
    margin-right: 0.75em; }
    .c-form_box__agreement .c-form_box__input.-check .mwform-checkbox-field {
      display: block;
      margin-top: 0; }
      .c-form_box__agreement .c-form_box__input.-check .mwform-checkbox-field:not(:last-child) {
        margin-right: 0; }
    .c-form_box__agreement .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text {
      padding-left: 1.75em;
      font-size: 0; }
  @media screen and (min-width: 992px) {
    .c-form_box__agreement .c-form_box__error {
      margin-top: 0;
      margin-left: 1em; } }
  @media screen and (min-width: 992px) {
    .c-form_box__agreement .c-form_box__error {
      width: 100%;
      margin-top: 1em;
      margin-left: 0; } }

.c-form_box__input textarea {
  height: 12em; }

@media screen and (min-width: 992px) {
  .c-form_box__input.-short .text_field {
    width: 40%; }
  .c-form_box__input.-middle .text_field {
    width: 60%; }
  .c-form_box__input.-long .text_field {
    width: 80%; } }

.c-form_box__input.-radio {
  line-height: 1;
  padding: 0.5em 0; }
  @media screen and (min-width: 576px) {
    .c-form_box__input.-radio {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap; } }
  .c-form_box__input.-radio .mwform-radio-field {
    display: block;
    margin-top: 1em; }
    .c-form_box__input.-radio .mwform-radio-field:not(:last-child) {
      margin-right: 2.5em; }
    .c-form_box__input.-radio .mwform-radio-field + .mwform-radio-field {
      margin-left: 0; }
  .c-form_box__input.-radio input[type="radio"] {
    display: none; }
    .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text {
      display: block;
      position: relative;
      min-width: 1.6rem;
      min-height: 1.6rem;
      padding-left: 2.4rem; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:before, .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:before {
        border: solid 1px #aaa; }
      .c-form_box__input.-radio input[type="radio"] + .mwform-radio-field-text:after {
        background: #0D0B01;
        transform: scale(0.35);
        visibility: hidden; }
    .c-form_box__input.-radio input[type="radio"]:checked + .mwform-radio-field-text:after {
      visibility: visible; }

.c-form_box__input.-check {
  line-height: 1;
  padding: 0.5em 0; }
  @media screen and (min-width: 576px) {
    .c-form_box__input.-check {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap; } }
  .c-form_box__input.-check .mwform-checkbox-field {
    display: block;
    margin-top: 1.6rem; }
    .c-form_box__input.-check .mwform-checkbox-field:not(:last-child) {
      margin-right: 4rem; }
    .c-form_box__input.-check .mwform-checkbox-field ~ .mwform-checkbox-field {
      margin-left: 0; }
  .c-form_box__input.-check input[type="checkbox"] {
    display: none; }
    .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text {
      display: block;
      position: relative;
      min-width: 1.6rem;
      min-height: 1.6rem;
      padding-left: 2.4rem; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:before, .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 1.6rem;
        height: 1.6rem; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:before {
        border: solid 2px #e0e0e0; }
      .c-form_box__input.-check input[type="checkbox"] + .mwform-checkbox-field-text:after {
        height: 1rem;
        width: 1.6rem;
        border-left: solid 3px #0D0B01;
        border-bottom: solid 3px #0D0B01;
        transform-origin: left bottom;
        transform: rotate(-45deg) translate(25%, 75%);
        visibility: hidden; }
    .c-form_box__input.-check input[type="checkbox"]:checked + .mwform-checkbox-field-text:after {
      visibility: visible; }

.c-form_box .mw_wp_form_preview .c-form_box__label {
  padding: 0; }

.c-form_box .mw_wp_form_preview .c-form_box__agreement {
  display: none; }

.c-form_box .mw_wp_form_preview .c-form_box__sheet tr.-top th,
.c-form_box .mw_wp_form_preview .c-form_box__sheet tr.-top td {
  vertical-align: middle; }

.c-form_box .mw_wp_form_preview .c-form_box__sheet tr.-top .c-form_box__label {
  padding-top: 0; }

.c-form_box .mw_wp_form_preview .c-form_box__submit {
  justify-content: flex-end; }

.-thanks {
  text-align: center; }
  .-thanks .c-ttl_pl {
    margin-bottom: 8rem; }
  @media screen and (max-width: 767px) {
    .-thanks .c-ttl_pl {
      font-size: 2rem; } }

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden; }
  .c-gmap iframe,
  .c-gmap object,
  .c-gmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden; }
  .c-img_ofi .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none; }
    .c-img_ofi .ofi.cover {
      object-fit: cover;
      object-position: center center;
      font-family: "object-fit: cover;object-position:center center;"; }
    .c-img_ofi .ofi.contain {
      margin: auto;
      object-fit: contain;
      font-family: "object-fit: contain;object-position:center center;"; }

/*--------------------------------------------
  FOLLOW
--------------------------------------------*/
.c-follow {
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 999;
  width: 20vw; }
  .c-follow__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff6c4;
    padding: .5em .75em;
    border: 3px solid #FBD70A;
    border-radius: 10px; }
    .c-follow__box figure {
      width: 25%;
      background: #fff; }
      .c-follow__box figure img {
        width: 100%; }
  .c-follow__txt {
    width: 72%; }
    .c-follow__txt span {
      font-size: 1.25em;
      font-weight: 500; }
    .c-follow__txt p {
      line-height: 1.34;
      font-size: .875em; }
  @media screen and (max-width: 1199px) {
    .c-follow {
      width: 30vw; } }
  @media screen and (max-width: 991px) {
    .c-follow {
      width: 100%;
      bottom: 0; }
      .c-follow__box {
        padding: .5em .75em; }
        .c-follow__box figure {
          width: 20%;
          text-align: center; }
          .c-follow__box figure img {
            max-width: 110px; }
      .c-follow__txt {
        width: 76%; }
        .c-follow__txt span {
          font-size: 1em; } }
  @media screen and (max-width: 767px) {
    .c-follow__box {
      padding: .5em; } }

/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto; }

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-ta_left {
  text-align: left; }

.c-ta_center {
  text-align: center; }

.c-ta_right {
  text-align: right; }

@media screen and (min-width: 576px) {
  .c-ta_justify {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.c-pe_none {
  pointer-events: none; }

.c-flex {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

@media screen and (min-width: 992px) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default; } }

.c-mgt0 {
  margin-top: 0; }

.c-mgt_ss {
  margin-top: 0.6em; }

.c-mgt_xs {
  margin-top: 1em; }

.c-mgt_sm {
  margin-top: 1.5em; }

.c-mgt_md {
  margin-top: 2em; }

.c-mgt_lg {
  margin-top: 2.5em; }

.c-mgt_xl {
  margin-top: 3em; }

.c-mgt_xxl {
  margin-top: 3.5em; }

@media screen and (max-width: 991px) {
  .c-mgt_ss {
    margin-top: 0.4em; }
  .c-mgt_xs {
    margin-top: 0.8em; }
  .c-mgt_sm {
    margin-top: 1.2em; }
  .c-mgt_md {
    margin-top: 1.6em; }
  .c-mgt_lg {
    margin-top: 2em; }
  .c-mgt_xl {
    margin-top: 2.4em; }
  .c-mgt_xxl {
    margin-top: 2.8em; } }

.c-mgl0 {
  margin-left: 0; }

.c-mgr0 {
  margin-right: 0; }

.c-mgb0 {
  margin-bottom: 0; }

.c-mgb_ss {
  margin-bottom: 0.6em; }

.c-mgb_xs {
  margin-bottom: 1em; }

.c-mgb_sm {
  margin-bottom: 1.5em; }

.c-mgb_md {
  margin-bottom: 2em; }

.c-mgb_lg {
  margin-bottom: 2.5em; }

.c-mgb_xl {
  margin-bottom: 3em; }

.c-mgb_xxl {
  margin-bottom: 3.5em; }

@media screen and (max-width: 991px) {
  .c-mgb_ss {
    margin-bottom: 0.4em; }
  .c-mgb_xs {
    margin-bottom: 0.8em; }
  .c-mgb_sm {
    margin-bottom: 1.2em; }
  .c-mgb_md {
    margin-bottom: 1.6em; }
  .c-mgb_lg {
    margin-bottom: 2em; }
  .c-mgb_xl {
    margin-bottom: 2.4em; }
  .c-mgb_xxl {
    margin-bottom: 2.8em; } }

.c-pdt0 {
  padding-top: 0; }

.c-pdl0 {
  padding-left: 0; }

.c-pdr0 {
  padding-right: 0; }

.c-pdb0 {
  padding-bottom: 0; }

/*-----------------------------------------------------
  Wordpress EDITOR
------------------------------------------------------*/
.wp_editor * + p,
.wp_editor * + ul,
.wp_editor * + ol,
.wp_editor * + .table_wrap {
  margin-top: 1.5em; }

.wp_editor * + h1,
.wp_editor * + h2,
.wp_editor * + h3,
.wp_editor * + h4 {
  margin-top: 2.5em; }

.wp_editor h2,
.wp_editor h3,
.wp_editor h4 {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.1rem; }

.wp_editor h2 {
  position: relative;
  padding-bottom: 0.5em;
  font-size: 2.6rem; }

.wp_editor h2::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2em;
  height: 0.2rem;
  background-color: #FBD70A; }

.wp_editor h3 {
  position: relative;
  padding-left: 1em;
  font-size: 2.4rem; }

.wp_editor h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.4em;
  left: 0;
  width: 0.5em;
  height: 0.5em;
  background-color: #FBD70A; }

.wp_editor h4 {
  font-size: 2rem; }

.wp_editor a {
  color: #194C97;
  font-weight: 600;
  word-break: break-all; }

.wp_editor a:hover,
.wp_editor a:focus {
  opacity: 0.7; }

.wp_editor ul,
.wp_editor ol {
  /*margin-bottom: 2rem;*/
  padding-left: 2em;
  line-height: 1.5; }

.wp_editor ul > li:not(:first-child),
.wp_editor ol > li:not(:first-child) {
  margin-top: 1em; }

.wp_editor ul > li {
  list-style-type: disc; }

.wp_editor ol > li {
  list-style-type: decimal; }

.wp_editor table {
  width: 100%; }

.wp_editor table tr > th,
.wp_editor table tr > td {
  padding: 1em;
  border: 1px solid #ddd; }

.wp_editor table tr > th {
  background-color: #a9a9a9; }

/*  Wordpress EDITOR SP  */
@media screen and (max-width: 767px) {
  .wp_editor * + p,
  .wp_editor * + ul,
  .wp_editor * + ol,
  .wp_editor * + .table_wrap {
    margin-top: 1em; }
  .wp_editor * + h1,
  .wp_editor * + h2,
  .wp_editor * + h3,
  .wp_editor * + h4 {
    margin-top: 2em; }
  .wp_editor h2 {
    font-size: 2.1rem; }
  .wp_editor h3 {
    font-size: 1.9rem; }
  .wp_editor h4 {
    font-size: 1.8rem; }
  .wp_editor ul > li:not(:first-child),
  .wp_editor ol > li:not(:first-child) {
    margin-top: 0.6em; } }

/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-family: "Passion One",cursive;
  font-size: 1.4em; }
  .wp-pagenavi > * {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 .5em; }
  .wp-pagenavi .current {
    opacity: 0.7; }
  .wp-pagenavi a:before, .wp-pagenavi a:after {
    position: absolute;
    background: #0D0B01;
    top: .5em; }
  .wp-pagenavi a:before {
    width: .45em;
    height: .5px; }
  .wp-pagenavi a:after {
    width: 1.25em;
    height: 1px; }
  @media screen and (min-width: 992px) {
    .wp-pagenavi a:hover {
      color: #FBD70A; } }
  @media screen and (max-width: 575px) {
    .wp-pagenavi a.previouspostslink {
      margin-right: 1em; }
    .wp-pagenavi a.nextpostslink {
      margin-left: 1em; }
    .wp-pagenavi .extend {
      display: none; } }

/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
.l-content {
  padding-bottom: 15rem; }
  @media screen and (max-width: 991px) {
    .l-content {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 767px) {
    .l-content {
      padding-bottom: 8rem; } }

.l-breadcrumb {
  line-height: 1;
  margin-top: 1.875em; }
  .l-breadcrumb__list {
    justify-content: flex-start;
    align-items: center; }
    .l-breadcrumb__list li {
      position: relative;
      padding-right: 1.8125em;
      margin-right: .5625em; }
      .l-breadcrumb__list li:after {
        position: absolute;
        content: "";
        right: 0;
        top: 50%;
        width: 1.25em;
        height: 1px;
        background: #0D0B01; }
      .l-breadcrumb__list li a {
        position: relative;
        display: block;
        padding: 5px 0;
        max-width: 30em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
        .l-breadcrumb__list li a:after {
          position: absolute;
          content: "";
          right: 0;
          left: initial;
          bottom: 0;
          width: 0%;
          height: 1px;
          background: #0D0B01;
          transition: all 0.6s; }
        .l-breadcrumb__list li a:hover:after {
          left: 0;
          right: initial;
          width: 100%; }
      .l-breadcrumb__list li:last-child {
        padding-right: 0;
        margin-right: 0; }
        .l-breadcrumb__list li:last-child:after {
          display: none; }
        .l-breadcrumb__list li:last-child a {
          pointer-events: none; }
          .l-breadcrumb__list li:last-child a:after {
            display: none; }

/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header {
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center; }
  .l-header__logo {
    width: 20%; }
    .l-header__logo a {
      display: block; }
      @media screen and (min-width: 992px) {
        .l-header__logo a:hover {
          opacity: 0.6; } }
    .l-header__logo img {
      max-width: 100%; }
  @media screen and (min-width: 992px) {
    .l-header__menu {
      width: 70%; } }
  .l-header__toggle {
    position: relative;
    z-index: 102;
    width: 5rem;
    height: 5rem; }
    .l-header__toggle .so_toggle {
      position: absolute;
      background: transparent;
      width: 72%;
      height: 72%;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .l-header__toggle .so_toggle .hamburger-icon {
        background: transparent;
        top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before, .l-header__toggle .so_toggle .hamburger-icon:after {
          background: #0D0B01;
          top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before {
          transform: translateY(-4px); }
        .l-header__toggle .so_toggle .hamburger-icon:after {
          transform: translateY(4px); }
  .slideout-open .l-header__toggle {
    background: rgba(255, 255, 255, 0); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before, .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      background: #fff; }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before {
      transform: rotate(45deg); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      transform: rotate(-45deg); }
  @media screen and (max-width: 991px) {
    .l-header .l-header__logo {
      width: 50%; } }

/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi {
  font-weight: 600;
  line-height: 1; }
  .l-gnavi__inner {
    max-width: 65%;
    width: 100%;
    margin-right: 4rem; }
  .l-gnavi__menu {
    justify-content: space-between; }
  .l-gnavi li a {
    display: flex;
    height: 100%;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3; }
    .l-gnavi li a span {
      display: flex;
      align-items: center; }
    @media screen and (min-width: 992px) {
      .l-gnavi li a:hover {
        color: #194C97; } }
  .l-gnavi__inquery {
    max-width: 215px;
    width: 100%;
    text-align: center; }
    .l-gnavi__inquery a {
      display: block;
      background: #194C97;
      color: #fff;
      padding: 3.5rem 1rem;
      border: 2px solid #194C97; }
      .l-gnavi__inquery a span {
        position: relative;
        padding-left: 4.3rem; }
        .l-gnavi__inquery a span:before {
          content: "";
          background: url("/recruit/assets/img/common/icon_mail.png") no-repeat;
          width: 32px;
          height: 25px;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition-duration: 0.3s; }
      .l-gnavi__inquery a:hover {
        background: #fff;
        color: #194C97; }
        .l-gnavi__inquery a:hover span:before {
          background: url("/recruit/assets/img/common/icon_mail_bl.png") no-repeat; }
  @media screen and (max-width: 1199px) {
    .l-gnavi__inner {
      max-width: 71%; }
    .l-gnavi li a {
      font-size: 1.4rem; }
    .l-gnavi__inquery {
      max-width: 160px; } }
  @media screen and (min-width: 992px) {
    .l-gnavi {
      position: static;
      background: transparent;
      width: auto;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 1; } }
  @media screen and (max-width: 991px) {
    .l-gnavi {
      background: rgba(0, 0, 0, 0.8);
      padding: 10rem 3rem 5rem;
      right: 0;
      transform: translateX(100%);
      width: 100%; }
      .l-gnavi__inner {
        max-width: 100%; }
      .l-gnavi__menu {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .l-gnavi__menu.-main {
          display: block;
          margin-bottom: 3rem; }
          .l-gnavi__menu.-main li {
            width: 100%; }
          .l-gnavi__menu.-main a {
            font-size: 1.25em;
            padding: 1em;
            text-align: center;
            color: #fff; }
            .l-gnavi__menu.-main a span {
              display: block;
              width: 100%;
              text-align: center; }
      .l-gnavi__inquery {
        margin: auto;
        max-width: 300px; }
        .l-gnavi__inquery a {
          padding: 3rem 1rem; }
      .slideout-open .l-gnavi {
        transform: translateX(0); }
      .l-gnavi .copy {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        font-size: .875em;
        text-align: center;
        padding: 1em 0; } }

/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer {
  position: relative;
  background: #0D0B01;
  color: #fff;
  padding: 4rem 0; }
  .l-footer .c-inner {
    justify-content: space-between;
    align-items: center; }
    .l-footer .c-inner .lbox {
      align-items: center;
      justify-content: center;
      margin-top: -1em;
      margin-left: -1em; }
      .l-footer .c-inner .lbox > * {
        margin-top: 1em;
        margin-left: 1em; }
      .l-footer .c-inner .lbox > a {
        display: block; }
        .l-footer .c-inner .lbox > a:hover {
          opacity: .8; }
    .l-footer .c-inner .copy {
      margin-top: 1.5em;
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.3; }
  @media screen and (max-width: 991px) {
    .l-footer .c-inner .lbox {
      width: auto;
      justify-content: space-between; }
      .l-footer .c-inner .lbox > a {
        width: 6em; }
    .l-footer .c-inner .copy {
      font-size: 1.4rem;
      text-align: center;
      width: 100%; } }
  @media screen and (max-width: 767px) {
    .l-footer .c-inner {
      text-align: center; }
      .l-footer .c-inner .lbox {
        text-align: center;
        justify-content: center; }
        .l-footer .c-inner .lbox .c-btn01 {
          width: 100%;
          margin-bottom: 1em; } }
  @media screen and (max-width: 575px) {
    .l-footer .c-inner {
      text-align: center; }
      .l-footer .c-inner .lbox {
        margin-left: -.6em; }
        .l-footer .c-inner .lbox > * {
          margin-left: .6em; }
        .l-footer .c-inner .lbox > a {
          width: calc(25% - .6em); } }

/*--------------------------------------------
  PAGE
--------------------------------------------*/
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key {
  position: relative;
  overflow: hidden; }
  .p-idx_key p {
    position: absolute;
    width: 3.5%; }
  .p-idx_key img {
    width: 100%; }
  .p-idx_key .txt {
    width: 61.9%;
    left: 48%;
    top: 33.3333%;
    transform: translate(-50%, -50%) scale(0);
    transition-duration: 0.3s; }
  .p-idx_key .copy {
    width: 77.9%;
    bottom: 9%;
    left: 50%;
    transform: translate(-50%, 0) scale(0);
    transition-duration: 0.5s; }
  .p-idx_key .chara-l02 {
    width: 16.6%;
    left: 8%;
    top: 50%;
    transform: translate(0, -50%) scale(0);
    transition-duration: 0.7s;
    transition-delay: 0.5s; }
  .p-idx_key .chara-r02 {
    width: 31.8%;
    left: 85%;
    top: 39%;
    transform: translate(-50%, -50%) scale(0);
    transition-duration: 0.7s;
    transition-delay: 1s; }
  .p-idx_key .deco {
    animation: deco 0.8s linear 0s infinite; }
  .p-idx_key .deco-07 {
    width: 10.7%;
    top: -10%;
    left: 2%;
    transform: translate(0, -50%); }
  .p-idx_key .deco-08 {
    width: 10.7%;
    bottom: -9%;
    right: 0;
    transform: translate(-50%, -50%) scale(-1, 1); }
  .p-idx_key.is-anime .txt {
    transform: translate(-50%, -50%) scale(1);
    transition-duration: 0.5s; }
  .p-idx_key.is-anime .copy {
    transform: translate(-50%, 0) scale(1);
    transition-duration: 0.7s; }
  .p-idx_key.is-anime .chara-l02 {
    transform: translate(0, -50%) scale(1);
    transition-duration: 0.9s; }
  .p-idx_key.is-anime .chara-r02 {
    transform: translate(-50%, -50%) scale(1);
    transition-duration: 0.9s; }

@keyframes deco {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(1); } }
  @media screen and (max-width: 767px) {
    .p-idx_key_bg {
      height: 20rem; }
      .p-idx_key_bg img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    .p-idx_key .txt {
      width: 50%;
      top: 37%; }
    .p-idx_key .copy {
      width: 67%;
      bottom: 6%; }
    .p-idx_key .chara-l02 {
      top: 42%; }
    .p-idx_key .chara-r02 {
      width: 26%;
      top: 32%; } }
  @media screen and (max-width: 575px) {
    .p-idx_key .copy {
      bottom: 11%; } }

/*-------------------------------
  INDEX > SubCOpy
-------------------------------*/
.p-idx_subcopy {
  background: url("../img/index/sub_copy_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  z-index: 2;
  position: relative;
  padding: 5rem 0; }
  .p-idx_subcopy img {
    transform: translateY(6rem);
    transition-duration: 0.3s;
    opacity: 0;
    max-width: 100%; }
  .p-idx_subcopy.is-anime img {
    transform: translateY(0rem);
    opacity: 1; }

/*-------------------------------
  INDEX > NEWS
-------------------------------*/
.p-idx .c-news__list {
  margin-bottom: 3em; }

/*-------------------------------
  INDEX > POINT
-------------------------------*/
.p-idx_point {
  padding: 15rem 0; }
  .p-idx_point__list {
    counter-reset: number 0;
    justify-content: space-between; }
  .p-idx_point li {
    width: 49%;
    background: #FFFBE4;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.3em 2em; }
    @media screen and (min-width: 768px) {
      .p-idx_point li:nth-child(n + 3) {
        margin-top: 1.5em; } }
    .p-idx_point li .baloon {
      position: absolute;
      left: 0;
      top: -1em;
      z-index: 2; }
      .p-idx_point li .baloon:before {
        counter-increment: number 1;
        content: "0" counter(number);
        font-size: 4.8rem;
        display: block;
        font-family: "Passion One", cursive;
        color: #0D0B01;
        line-height: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    .p-idx_point li .icon {
      position: relative;
      background: #FBD70A;
      border-radius: 50%;
      width: 44%;
      padding-top: 44%;
      transform: scale(0);
      transition-duration: 0.6s; }
      .p-idx_point li .icon img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%; }
    .p-idx_point li .txt {
      width: 50%;
      font-size: 1.25vw;
      font-weight: 600; }
      .p-idx_point li .txt span {
        background: linear-gradient(transparent 80%, #FBD70A 80%); }
  .p-idx_point.is-anime li:nth-child(1) .icon {
    transition-delay: 0.5s; }
  .p-idx_point.is-anime li:nth-child(2) .icon {
    transition-delay: 1s; }
  .p-idx_point.is-anime li:nth-child(3) .icon {
    transition-delay: 1.5s; }
  .p-idx_point.is-anime li:nth-child(4) .icon {
    transition-delay: 2s; }
  .p-idx_point.is-anime li .icon {
    transform: scale(1); }
  @media screen and (max-width: 1599px) {
    .p-idx_point li .baloon {
      width: 18%; }
      .p-idx_point li .baloon img {
        width: 100%; }
      .p-idx_point li .baloon:before {
        font-size: 4.8rem; }
    .p-idx_point li .icon {
      width: 35%;
      padding-top: 35%; }
      .p-idx_point li .icon img {
        max-width: 50%; }
    .p-idx_point li .txt {
      width: 60%;
      font-size: 1.7vw; } }
  @media screen and (max-width: 991px) {
    .p-idx_point {
      padding: 10rem 0; }
      .p-idx_point li {
        padding: 1em; }
        .p-idx_point li .baloon:before {
          font-size: 3rem; }
        .p-idx_point li .icon {
          width: 30%;
          padding-top: 30%; }
          .p-idx_point li .icon img {
            max-width: 60%; }
        .p-idx_point li .txt {
          width: 65%;
          font-size: 2vw; } }
  @media screen and (max-width: 767px) {
    .p-idx_point {
      padding: 8rem 0; }
      .p-idx_point__list {
        display: block; }
        .p-idx_point__list li {
          width: 100%; }
          .p-idx_point__list li:nth-child(n + 2) {
            margin-top: 2rem; }
          .p-idx_point__list li .baloon {
            width: 13%; }
            .p-idx_point__list li .baloon:before {
              font-size: 2.5rem; }
          .p-idx_point__list li .txt {
            width: 65%;
            font-size: 4.6vw; } }

/*-------------------------------
  INDEX > MOVIE
-------------------------------*/
.p-idx_movie {
  padding: 15rem 0; }
  .p-idx_movie__slide-wrap {
    position: relative; }
    .p-idx_movie__slide-wrap:before, .p-idx_movie__slide-wrap:after {
      content: "";
      position: absolute;
      display: block;
      top: 30%;
      transition-duration: 0.6s;
      opacity: 0; }
    .p-idx_movie__slide-wrap:before {
      background: url("../img/index/idx_movie_l.png") no-repeat;
      width: 138px;
      height: 251px;
      left: 7%;
      transition-delay: 1s; }
    .p-idx_movie__slide-wrap:after {
      background: url("../img/index/idx_movie_r.png") no-repeat;
      width: 136px;
      height: 235px;
      right: 7%;
      transition-delay: 1.5s; }
    .p-idx_movie__slide-wrap.is-anime:before, .p-idx_movie__slide-wrap.is-anime:after {
      opacity: 1; }
    .p-idx_movie__slide-wrap.is-anime:before {
      left: -1.4%; }
    .p-idx_movie__slide-wrap.is-anime:after {
      right: -1.4%; }
  .p-idx_movie__slide {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 4rem;
    position: relative;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    z-index: 2; }
    .p-idx_movie__slide:before {
      content: "";
      background: #fff;
      border-radius: 15px;
      width: 104%;
      height: 106%;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .p-idx_movie__slide li {
      position: relative;
      padding-top: 35em;
      width: 100%;
      height: 0; }
      .p-idx_movie__slide li iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .p-idx_movie__slide .slide-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3; }
    .p-idx_movie__slide .prev-arrow {
      left: -3rem; }
    .p-idx_movie__slide .next-arrow {
      right: -3rem;
      transform: translateY(-50%) rotate(180deg); }
    .p-idx_movie__slide.is-anime {
      transform: translateY(0rem);
      opacity: 1; }
  .p-idx_movie__thum {
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s; }
    .p-idx_movie__thum .slick-track {
      padding: 2.5% 0; }
    .p-idx_movie__thum li {
      position: relative;
      padding-top: 11em;
      width: 100%;
      height: 0;
      position: relative;
      margin: 0 2vw; }
      .p-idx_movie__thum li:before {
        content: "";
        background: #fff;
        width: 108%;
        height: 114%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px; }
      .p-idx_movie__thum li iframe {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .p-idx_movie__thum.is-anime {
      transform: translateY(0rem);
      opacity: 1; }
    .p-idx_movie__thum.-wrap .slick-list {
      margin-top: -3em; }
      .p-idx_movie__thum.-wrap .slick-list .slick-track {
        width: 100% !important;
        transform: translate3d(0px, 0px, 0px) !important; }
        .p-idx_movie__thum.-wrap .slick-list .slick-track .slick-slide {
          width: calc(33.3333% - 4vw) !important;
          margin-top: 3em; }
  @media screen and (max-width: 1299px) {
    .p-idx_movie__slide-wrap:before, .p-idx_movie__slide-wrap:after {
      display: none; } }
  @media screen and (max-width: 991px) {
    .p-idx_movie {
      padding: 10rem 0; }
      .p-idx_movie__slide li {
        padding-top: 52vw; }
      .p-idx_movie__thum.-wrap .slick-list {
        margin-top: -2em; }
        .p-idx_movie__thum.-wrap .slick-list .slick-track .slick-slide {
          margin-top: 2em;
          padding-top: 7em; } }
  @media screen and (max-width: 767px) {
    .p-idx_movie {
      padding: 8rem 0; }
      .p-idx_movie .slide-arrow {
        width: 4rem;
        height: 4rem; }
        .p-idx_movie .slide-arrow img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_movie .prev-arrow {
        left: -2rem; }
      .p-idx_movie .next-arrow {
        right: -2rem; }
      .p-idx_movie__thum.-wrap .slick-list .slick-track .slick-slide {
        width: calc(50% - 4vw) !important;
        padding-top: 9em; } }
  @media screen and (max-width: 575px) {
    .p-idx_movie__thum.-wrap .slick-list .slick-track .slick-slide {
      padding-top: 6em; } }

/*-------------------------------
  INDEX > INFOGRAPHICS
-------------------------------*/
.p-idx_infographics {
  padding: 15rem 0; }
  .p-idx_infographics__date {
    background: #fff;
    padding: 5%;
    position: relative; }
    .p-idx_infographics__date:before, .p-idx_infographics__date:after {
      content: "";
      position: absolute;
      display: block;
      top: -9rem; }
    @media screen and (max-width: 1199px) {
      .p-idx_infographics__date:before, .p-idx_infographics__date:after {
        display: none; } }
    .p-idx_infographics__date:before {
      background: url("../img/index/idx_num_l.png") no-repeat;
      width: 100px;
      height: 177px;
      left: 0; }
    .p-idx_infographics__date:after {
      background: url("../img/index/idx_num_r.png") no-repeat;
      width: 98px;
      height: 177px;
      right: 0; }
  .p-idx_infographics__item {
    display: flex;
    align-items: center;
    width: 50%;
    line-height: 1; }
    @media screen and (min-width: 768px) {
      .p-idx_infographics__item:nth-child(n + 3) {
        margin-top: 4em; } }
    .p-idx_infographics__item.full {
      width: 100%; }
      .p-idx_infographics__item.full .img {
        width: 52%; }
      .p-idx_infographics__item.full .txt {
        width: 100%;
        max-width: 12.3em;
        letter-spacing: 1.6px; }
      .p-idx_infographics__item.full .annotation {
        text-align: right; }
    .p-idx_infographics__item.employee .img {
      width: 40%; }
    .p-idx_infographics__item.colmun {
      width: 33%;
      display: block;
      text-align: center; }
      .p-idx_infographics__item.colmun .img {
        text-align: center;
        margin: 0 0 2rem;
        width: 100%;
        height: 17vh; }
        .p-idx_infographics__item.colmun .img img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_infographics__item.colmun .sm {
        font-size: 2vw;
        margin-bottom: 2.5rem; }
      .p-idx_infographics__item.colmun .num {
        font-size: 2.4vw;
        font-weight: 600; }
        .p-idx_infographics__item.colmun .num span {
          font-size: 4vw;
          font-weight: 600; }
    .p-idx_infographics__item.leave .img {
      width: 25%; }
    .p-idx_infographics__item.leave .txt {
      width: 75%;
      text-align: center; }
    .p-idx_infographics__item.leave .annotation {
      max-width: 100%;
      text-align: right; }
    .p-idx_infographics__item.time {
      width: 50%;
      display: block;
      text-align: center; }
      .p-idx_infographics__item.time .img {
        text-align: center;
        margin: 0 0 2rem;
        width: 100%;
        height: 17vh; }
        .p-idx_infographics__item.time .img img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_infographics__item.time .sm {
        font-size: 2vw;
        margin-bottom: 2.5rem; }
      .p-idx_infographics__item.time .num {
        font-size: 2.4vw;
        font-weight: 600; }
        .p-idx_infographics__item.time .num span {
          font-size: 4vw;
          font-weight: 600; }
    .p-idx_infographics__item.is-anime .img {
      transform: scale(1); }
    .p-idx_infographics__item.is-anime.item01 .img {
      transition-delay: 0.5s; }
    .p-idx_infographics__item.is-anime.item02 .img {
      transition-delay: 1s; }
    .p-idx_infographics__item.is-anime.item03 .img {
      transition-delay: 0.5s; }
    .p-idx_infographics__item.is-anime.item04 .img {
      transition-delay: 0.5s; }
    .p-idx_infographics__item.is-anime.item05 .img {
      transition-delay: 1s; }
    .p-idx_infographics__item.is-anime.item06 .img {
      transition-delay: 0.3s; }
    .p-idx_infographics__item.is-anime.item07 .img {
      transition-delay: 0.6s; }
    .p-idx_infographics__item.is-anime.item08 .img {
      transition-delay: 0.9s; }
    .p-idx_infographics__item.is-anime.item09 .img {
      transition-delay: 1.2s; }
    .p-idx_infographics__item.is-anime.item10 .img {
      transition-delay: 1.5s; }
  .p-idx_infographics .img {
    margin-right: 1em;
    width: 45%;
    transform: scale(0);
    transition-duration: 0.6s; }
    .p-idx_infographics .img img {
      max-width: 100%; }
  .p-idx_infographics .txt {
    font-size: 2.3vw;
    font-weight: 600;
    width: 55%; }
  .p-idx_infographics .lg {
    margin-bottom: 0.3em; }
  .p-idx_infographics .num span {
    font-size: 5vw; }
  .p-idx_infographics .annotation {
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center; }
  @media screen and (max-width: 991px) {
    .p-idx_infographics {
      padding: 10rem 0; }
      .p-idx_infographics__item.full .txt {
        max-width: 13.3em; }
      .p-idx_infographics__item.leave .annotation {
        text-align: center; }
      .p-idx_infographics__item.colmun .sm {
        font-size: 3.1vw; }
      .p-idx_infographics__item.colmun .img {
        height: 12vh;
        margin-bottom: 2rem; }
        .p-idx_infographics__item.colmun .img img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_infographics__item.time .sm {
        font-size: 3.1vw; }
      .p-idx_infographics__item.time .img {
        height: 12vh;
        margin-bottom: 2rem; }
        .p-idx_infographics__item.time .img img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_infographics .txt {
        font-size: 2.9vw; }
      .p-idx_infographics .num span {
        font-size: 7vw; }
      .p-idx_infographics .annotation {
        font-size: 1.6rem;
        margin-top: 1rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_infographics {
      padding: 8rem 0; }
      .p-idx_infographics__date {
        display: block; }
      .p-idx_infographics__item {
        width: 100%;
        border-bottom: 2px solid #eaeaea;
        padding-bottom: 2em; }
        .p-idx_infographics__item:nth-child(n + 2) {
          margin-top: 2em; }
        .p-idx_infographics__item:last-child {
          border: none; }
        .p-idx_infographics__item.full {
          display: block; }
          .p-idx_infographics__item.full .img {
            width: 100%;
            margin-bottom: 0.5em; }
          .p-idx_infographics__item.full .txt {
            width: 100%; }
          .p-idx_infographics__item.full .annotation {
            text-align: right; }
        .p-idx_infographics__item.colmun, .p-idx_infographics__item.time {
          width: 100%; }
          .p-idx_infographics__item.colmun .img, .p-idx_infographics__item.time .img {
            width: 100%;
            height: 10rem; }
          .p-idx_infographics__item.colmun .sm, .p-idx_infographics__item.time .sm {
            font-size: 3rem;
            margin-bottom: 1.5rem; }
          .p-idx_infographics__item.colmun .num, .p-idx_infographics__item.time .num {
            font-size: 4rem;
            font-weight: 600; }
            .p-idx_infographics__item.colmun .num span, .p-idx_infographics__item.time .num span {
              font-size: 5rem; }
      .p-idx_infographics .txt {
        font-size: 2.8rem; }
      .p-idx_infographics .num span {
        font-size: 5rem; } }

/*-------------------------------
  INDEX > RECRUIT
-------------------------------*/
.p-question {
  padding: 0 0 15rem; }
  @media screen and (max-width: 991px) {
    .p-question {
      padding: 0 0 10rem; } }
  @media screen and (max-width: 767px) {
    .p-question {
      padding: 0 0 8rem; } }

@media screen and (min-width: 768px) {
  .p-question_box {
    display: flex;
    justify-content: space-between; }
    .p-question_box > div {
      width: 48.3%; } }

.p-question_box__item {
  padding: 1em;
  background: #fff;
  font-size: 3.6rem; }
  @media screen and (min-width: 768px) {
    .p-question_box__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; } }
  .p-question_box__item:nth-child(n+2) {
    margin-top: 1em; }
  .p-question_box__item.-carimg {
    position: relative; }
    @media screen and (min-width: 1200px) {
      .p-question_box__item.-carimg:before {
        content: "";
        background: url("../img/index/car_image.png") no-repeat;
        width: 134px;
        height: 79px;
        display: block;
        position: absolute;
        left: -0.5em;
        bottom: 0.5em; } }
  .p-question_box__item.js-anime {
    transform: translateY(6rem);
    transition-duration: 0.3s;
    opacity: 0; }
    .p-question_box__item.js-anime.is-anime {
      transform: translateY(0rem);
      opacity: 1; }
  .p-question_box__item img {
    max-width: 100%; }

.p-question_box__ttl {
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: .75em; }
  @media screen and (min-width: 1200px) {
    .p-question_box__ttl.-w5em {
      margin-bottom: 0;
      width: 5.5em; }
      .p-question_box__ttl.-w5em + .p-question_box__images {
        width: calc(100% - 5.5em); } }

.p-question_box__images {
  text-align: right; }

@media screen and (max-width: 1199px) {
  .p-question_box__item {
    text-align: center; }
  .p-question_box__ttl {
    text-align: center;
    width: 100%; }
  .p-question_box__images {
    text-align: center;
    margin: auto; } }

@media screen and (max-width: 991px) {
  .p-question_box__item {
    font-size: 2.6rem; } }

@media screen and (max-width: 767px) {
  .p-question_box__item {
    font-size: 2.4rem; }
  .p-question_box__right {
    margin-top: 1.5em; } }

/*-------------------------------
  INDEX > INSTAGRAM
-------------------------------*/
.p-insta {
  padding: 15rem 0; }
  @media screen and (max-width: 991px) {
    .p-insta {
      padding: 10rem 0; } }
  @media screen and (max-width: 767px) {
    .p-insta {
      padding: 8rem 0; } }

/*-------------------------------
  INDEX > VOICE
-------------------------------*/
.p-idx_voice {
  padding: 15rem 0; }
  .p-idx_voice .c-inner {
    max-width: 100%; }
  .p-idx_voice__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; }
    .p-idx_voice__list li {
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translateY(6rem);
      opacity: 0;
      transition-duration: 0.6s; }
      .p-idx_voice__list li:nth-child(1) {
        transition-delay: 0.5s; }
      .p-idx_voice__list li:nth-child(2) {
        transition-delay: 1s; }
      .p-idx_voice__list li:nth-child(3) {
        transition-delay: 1.5s; }
      .p-idx_voice__list li:nth-child(4) {
        transition-delay: 2s; }
      .p-idx_voice__list li:nth-child(5) {
        transition-delay: 2.5s; }
      .p-idx_voice__list li:nth-child(6) {
        transition-delay: 3s; }
      .p-idx_voice__list li:nth-child(7) {
        transition-delay: 3.5s; }
      .p-idx_voice__list li:nth-child(8) {
        transition-delay: 4s; }
      @media screen and (min-width: 768px) {
        .p-idx_voice__list li:nth-child(n + 1):nth-child(even) {
          margin-top: 4rem; }
        .p-idx_voice__list li:nth-child(even) {
          flex-direction: row-reverse;
          transform: translateY(10rem); }
          .p-idx_voice__list li:nth-child(even) .p-idx_voice__txt:before {
            left: initial;
            right: -1em;
            border-right: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 30px solid #FFFBE4; } }
  .p-idx_voice__icon {
    background: #FBD70A;
    position: relative;
    width: 27%;
    padding-top: 27%;
    border-radius: 50%; }
    .p-idx_voice__icon img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%; }
  .p-idx_voice__txt {
    width: 66%;
    background: #FFFBE4;
    border-radius: 5px;
    padding: 1em;
    font-size: 2.2rem;
    position: relative;
    font-weight: 600;
    line-height: 1.4; }
    .p-idx_voice__txt:before {
      content: "";
      border-top: 20px solid transparent;
      border-right: 30px solid #FFFBE4;
      border-bottom: 20px solid transparent;
      position: absolute;
      left: -1em;
      top: 50%;
      transform: translateY(-50%); }
  .p-idx_voice.is-anime .p-idx_voice__list li {
    transform: translateY(0rem);
    opacity: 1; }
  @media screen and (max-width: 991px) {
    .p-idx_voice {
      padding: 10rem 0 15rem; }
      .p-idx_voice .c-inner {
        max-width: 92%; }
      .p-idx_voice__icon {
        width: 9rem;
        height: 9rem;
        padding-top: 0; }
        .p-idx_voice__icon img {
          width: auto;
          height: 70%; }
      .p-idx_voice__txt {
        font-size: 1.6rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_voice {
      padding: 10rem 0; }
      .p-idx_voice__list {
        display: block; }
        .p-idx_voice__list li {
          width: 100%; }
          .p-idx_voice__list li:nth-child(n + 2) {
            margin-top: 1rem; }
      .p-idx_voice__txt {
        font-size: 1.5rem;
        position: relative; }
        .p-idx_voice__txt:before {
          left: -1em;
          border-top: 10px solid transparent;
          border-right: 20px solid #fffbe4;
          border-bottom: 10px solid transparent; } }

/*-------------------------------
  INDEX > PHOTO
-------------------------------*/
.p-idx_photo {
  padding: 15rem 0; }
  .p-idx_photo__list {
    position: relative;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.5s; }
    .p-idx_photo__list li.slick-slide {
      border: 10px solid #fff;
      border-radius: 15px;
      overflow: hidden;
      margin: 0 1rem; }
      .p-idx_photo__list li.slick-slide img {
        max-width: 100%; }
    .p-idx_photo__list .slide-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3; }
    .p-idx_photo__list .prev-arrow {
      left: -1em; }
    .p-idx_photo__list .next-arrow {
      right: -1em;
      transform: translateY(-50%) rotate(180deg); }
  .p-idx_photo .slick-dots {
    bottom: -6rem; }
    .p-idx_photo .slick-dots li button {
      color: #fff; }
      .p-idx_photo .slick-dots li button:before {
        color: #fff;
        opacity: 1;
        font-family: initial;
        font-size: 3.5rem; }
    .p-idx_photo .slick-dots li.slick-active button {
      position: relative; }
      .p-idx_photo .slick-dots li.slick-active button:after {
        content: "";
        border: 3px solid #0D0B01;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
  .p-idx_photo.is-anime .p-idx_photo__list {
    transform: translateY(0rem);
    opacity: 1; }
  @media screen and (max-width: 991px) {
    .p-idx_photo {
      padding: 10rem 0; }
      .p-idx_photo__list .slide-arrow {
        height: 4rem; }
        .p-idx_photo__list .slide-arrow img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .p-idx_photo__list .prev-arrow {
        left: -1em; }
      .p-idx_photo__list .next-arrow {
        right: -1em;
        transform: translateY(-50%) rotate(180deg); } }

/*-------------------------------
  INDEX > BENEFITS
-------------------------------*/
.p-idx_benefits {
  padding: 15rem 0;
  overflow: hidden;
  z-index: 3;
  position: relative;
  background: #fff; }
  .p-idx_benefits .js-anime {
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: .6s; }
  .p-idx_benefits .is-anime {
    transform: translateY(0rem);
    opacity: 1; }
  @media screen and (max-width: 991px) {
    .p-idx_benefits {
      padding: 10rem 0; } }
  .p-idx_benefits .c-ttl01 {
    position: relative;
    margin-bottom: 8rem; }
    @media screen and (min-width: 1200px) {
      .p-idx_benefits .c-ttl01:before, .p-idx_benefits .c-ttl01:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .p-idx_benefits .c-ttl01:before {
        background: url("../img/index/benefits_ttl03.png") no-repeat;
        width: 126px;
        height: 236px;
        left: 0; }
      .p-idx_benefits .c-ttl01:after {
        background: url("../img/index/benefits_ttl04.png") no-repeat;
        width: 171px;
        height: 220px;
        right: 0; } }
    @media screen and (max-width: 767px) {
      .p-idx_benefits .c-ttl01 {
        margin-bottom: 4rem; } }
  .p-idx_benefits__ttl {
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 1;
    position: relative; }
    @media screen and (min-width: 992px) {
      .p-idx_benefits__ttl {
        width: 25%; } }
    .p-idx_benefits__ttl h3 {
      position: relative; }
      @media screen and (min-width: 992px) {
        .p-idx_benefits__ttl h3 {
          padding-left: 1.8em; } }
      .p-idx_benefits__ttl h3:before {
        content: "";
        background: url("../img/common/icon_arrow03.png") no-repeat;
        width: 45px;
        height: 62px;
        display: block;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg); }
    @media screen and (max-width: 991px) {
      .p-idx_benefits__ttl {
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: 1em;
        display: inline-block;
        padding-left: 2em;
        width: 100%; }
        .p-idx_benefits__ttl h3 {
          display: inline-block; }
          .p-idx_benefits__ttl h3:before {
            left: -1.6em;
            width: 34px;
            height: 51px; } }
  .p-idx_benefits__inner {
    padding: 2em;
    position: relative; }
    @media screen and (min-width: 992px) {
      .p-idx_benefits__inner {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    .p-idx_benefits__inner:nth-child(odd) {
      background: #FBD70A; }
    .p-idx_benefits__inner:nth-child(even) {
      background: #FFFBE4; }
    .p-idx_benefits__inner:before {
      content: "";
      display: block;
      position: absolute;
      left: 6em;
      bottom: 0;
      transform: translateY(50%);
      z-index: 2; }
    @media screen and (min-width: 992px) {
      .p-idx_benefits__inner:nth-child(1) {
        z-index: 7; }
        .p-idx_benefits__inner:nth-child(1):before {
          background: url("../img/index/car_image.png") no-repeat;
          width: 134px;
          height: 79px; }
      .p-idx_benefits__inner:nth-child(2) {
        z-index: 6; }
        .p-idx_benefits__inner:nth-child(2):before {
          background: url("../img/common/icon_pad.png") no-repeat;
          width: 95px;
          height: 109px; }
      .p-idx_benefits__inner:nth-child(3) {
        z-index: 5; }
        .p-idx_benefits__inner:nth-child(3):before {
          background: url("../img/common/icon_meat.png") no-repeat;
          width: 147px;
          height: 86px; }
      .p-idx_benefits__inner:nth-child(4) {
        z-index: 4; }
        .p-idx_benefits__inner:nth-child(4):before {
          background: url("../img/common/c-ttl_r.png") no-repeat;
          width: 80px;
          height: 65px;
          left: 0; }
      .p-idx_benefits__inner:nth-child(5) {
        z-index: 3; }
        .p-idx_benefits__inner:nth-child(5):before {
          background: url("../img/common/icon_pencil.png") no-repeat;
          width: 140px;
          height: 98px; }
      .p-idx_benefits__inner:nth-child(6) {
        z-index: 2; }
        .p-idx_benefits__inner:nth-child(6):before {
          background: url("../img/common/icon_spanner.png") no-repeat;
          width: 156px;
          height: 95px; } }
    @media screen and (max-width: 767px) {
      .p-idx_benefits__inner {
        padding: 2em 0.5em; } }
  @media screen and (min-width: 768px) {
    .p-idx_benefits__box {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap; } }
  @media screen and (min-width: 992px) {
    .p-idx_benefits__box {
      width: 74.5%; } }
  .p-idx_benefits__box li {
    border: 4px solid #000;
    border-radius: 10px;
    background: #fff;
    padding: 1.5em;
    position: relative; }
    @media screen and (min-width: 768px) {
      .p-idx_benefits__box li {
        width: 49.5%; } }
    .p-idx_benefits__box li:nth-child(n+3) {
      margin-top: 0.5em; }
    .p-idx_benefits__box li:before {
      content: "";
      background: url("../img/common/icon_ribon.png") no-repeat;
      width: 65px;
      height: 51px;
      display: block;
      position: absolute;
      right: -0.7em;
      top: -0.7em;
      background-size: contain; }
    .p-idx_benefits__box li.-full {
      width: 100%; }
    @media screen and (max-width: 767px) {
      .p-idx_benefits__box li:nth-child(n+2) {
        margin-top: 0.5em; } }
  .p-idx_benefits__box span {
    display: block;
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.4em;
    line-height: 1.3; }
    @media screen and (max-width: 767px) {
      .p-idx_benefits__box span {
        font-size: 2rem; } }
  .p-idx_benefits__box p {
    line-height: 1.6; }
  .p-idx_benefits__bubble {
    position: relative; }
    @media screen and (min-width: 1200px) {
      .p-idx_benefits__bubble::before {
        content: url(../img/index/benefits_bubble.png);
        position: absolute;
        position: absolute;
        width: 70%;
        height: 180px;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%); } }
  @media screen and (min-width: 1200px) and (min-width: 1200px) and (max-width: 1400px) {
    .p-idx_benefits__bubble::before {
      width: 56%;
      left: 40%; } }

.p-idx_benefits_more {
  position: relative;
  max-width: 104rem;
  margin: 0 auto; }
  .p-idx_benefits_more__whbox {
    padding: 1.2em;
    background-color: #fff;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.4s; }
  .p-idx_benefits_more__deco01, .p-idx_benefits_more__deco02 {
    position: absolute;
    bottom: 0;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.4s;
    transition-delay: 1.6s; }
    .p-idx_benefits_more__deco01::before, .p-idx_benefits_more__deco02::before {
      content: "";
      display: block;
      position: absolute;
      background: url("../img/common/c-ttl_l.png") center/contain no-repeat;
      width: 6.1rem;
      height: 5.6rem;
      animation: t-deco 2s linear 0s infinite; }
  .p-idx_benefits_more__deco01 {
    left: -12rem;
    width: 25.6rem; }
    .p-idx_benefits_more__deco01::before {
      top: 2em;
      right: 0; }
  .p-idx_benefits_more__deco02 {
    right: -4rem;
    width: 22.3rem; }
    .p-idx_benefits_more__deco02::before {
      top: 2em;
      left: -2em; }
  .p-idx_benefits_more__btn {
    z-index: 1;
    position: relative;
    margin-top: -8rem;
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 1s; }
    .p-idx_benefits_more__btn > a {
      margin: 0 auto; }
  .is-anime .p-idx_benefits_more .p-idx_benefits_more__whbox,
  .is-anime .p-idx_benefits_more .p-idx_benefits_more__deco01,
  .is-anime .p-idx_benefits_more .p-idx_benefits_more__deco02 {
    transform: translateY(0rem);
    opacity: 1; }
  .is-anime .p-idx_benefits_more .p-idx_benefits_more__btn {
    opacity: 1; }
  @media screen and (max-width: 1599px) {
    .p-idx_benefits_more__deco01 {
      left: -8rem; } }
  @media screen and (max-width: 1199px) {
    .p-idx_benefits_more__deco01 {
      left: -4rem;
      width: 21rem; }
    .p-idx_benefits_more__deco02 {
      width: 20rem; } }
  @media screen and (max-width: 991px) {
    .p-idx_benefits_more__whbox {
      padding: 1em; }
    .p-idx_benefits_more__deco01::before, .p-idx_benefits_more__deco02::before {
      width: 4.4rem;
      height: 4rem; }
    .p-idx_benefits_more__deco01 {
      left: -2rem;
      width: 16rem; }
      .p-idx_benefits_more__deco01::before {
        top: 1em;
        right: -1em; }
    .p-idx_benefits_more__deco02 {
      right: -2rem;
      width: 14rem; }
      .p-idx_benefits_more__deco02::before {
        top: 1em; }
    .p-idx_benefits_more__btn {
      margin-top: -6rem; } }
  @media screen and (max-width: 575px) {
    .p-idx_benefits_more__deco01, .p-idx_benefits_more__deco02 {
      bottom: 3em; }
    .p-idx_benefits_more__deco01 {
      left: -2rem;
      width: 10rem; }
      .p-idx_benefits_more__deco01::before {
        top: 0.5em;
        right: -1.5em; }
    .p-idx_benefits_more__deco02 {
      right: -2rem;
      width: 8rem; }
      .p-idx_benefits_more__deco02::before {
        top: 0;
        left: -2.5em; }
    .p-idx_benefits_more__btn {
      margin-top: -3rem; } }

/*-------------------------------
  INDEX > comment
-------------------------------*/
.p-index_comment {
  padding: 7rem 0;
  text-align: center;
  position: relative; }
  .p-index_comment img {
    max-width: 100%; }
  .p-index_comment.js-anime img {
    transform: translateY(6rem);
    transition-duration: 0.3s;
    opacity: 0; }
  .p-index_comment.js-anime.is-anime img {
    transform: translateY(0rem);
    opacity: 1; }
  .p-index_comment:before {
    content: "";
    border-top: 6rem solid #FBD70A;
    border-right: 15rem solid transparent;
    border-left: 15rem solid transparent;
    position: absolute;
    left: 50%;
    bottom: -6rem;
    transform: translateX(-50%); }

/*============================================

  RECRUIT

============================================*/
/*-------------------------------
  RECRUIT > INTRO
-------------------------------*/
/*============================================

  OTHER PAGE

============================================*/
.p-requirements_info__box.new {
  margin-bottom: 8rem;
  padding-bottom: 8rem;
  border-bottom: 1px solid #ddd; }

.p-requirements_info__tbl td em {
  font-style: initial;
  font-weight: 600;
  display: block;
  margin-top: 1.5em;
  color: #194C97; }
  .p-requirements_info__tbl td em:first-child {
    margin-top: 0; }

.p-requirements_info__tbl td span {
  display: inline-block;
  background: #194C97;
  border-radius: 5px;
  color: #fff;
  line-height: 1;
  padding: 0.5em;
  margin-right: 0.5em; }

.p-requirements_info__tbl td ul {
  margin-top: 1em; }
  .p-requirements_info__tbl td ul:first-child {
    margin-top: 0; }
  .p-requirements_info__tbl td ul li {
    position: relative;
    padding-left: 1em; }
    .p-requirements_info__tbl td ul li:before {
      content: "";
      background: #194C97;
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 0.7em;
      left: 0; }

@media screen and (max-width: 767px) {
  .p-requirements_info__tbl td span {
    margin-top: 0.5em; } }

/*-------------------------------
  NEWS
-------------------------------*/
.p-news .c-news {
  background: #FFFBE4; }

.p-news .c-news__list {
  transform: translateY(0rem);
  transition-delay: 1.5s;
  opacity: 1; }

.p-news_single {
  background: #FFFBE4;
  padding: 4em 0; }
  .p-news_single .c-inner {
    background: #fff;
    border-radius: 15px;
    padding: 5em; }
    @media screen and (max-width: 767px) {
      .p-news_single .c-inner {
        padding: 2em; } }
  .p-news_single__head {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5em;
    margin-bottom: 2.5em; }
  .p-news_single__info {
    display: flex;
    align-items: center;
    font-family: "Passion One", cursive;
    line-height: 1;
    margin-bottom: 1em; }
    .p-news_single__info time {
      font-size: 1.6em;
      margin-right: 1em; }
    .p-news_single__info .term {
      background: #0D0B01;
      color: #fff;
      font-size: 1.4em;
      padding: 0.1em 0.4em; }
      @media screen and (max-width: 767px) {
        .p-news_single__info .term {
          font-size: 1.2em; } }
  .p-news_single__ttl {
    font-size: 2em;
    font-weight: 600;
    line-height: 1.4; }
    @media screen and (max-width: 767px) {
      .p-news_single__ttl {
        font-size: 1.4em; } }
  .p-news_single__body {
    margin-bottom: 3em; }
  .p-news_single__foot .c-btn01 {
    max-width: 250px;
    text-align: center;
    margin: 0 auto; }

/*-------------------------------
  ENTRY
-------------------------------*/
.p-entry_info {
  padding: 10rem 0 0; }
  @media screen and (max-width: 991px) {
    .p-entry_info {
      padding: 8rem 0 0; } }
  .p-entry_info .read {
    text-align: center;
    margin-bottom: 3rem; }
  .p-entry_info .pp_area .pp {
    border: 1px solid #ddd;
    padding: 1em;
    height: 20rem;
    overflow-y: scroll;
    margin-bottom: 1em; }
  .p-entry_info .pp_area .form_input {
    text-align: center;
    margin-bottom: 3rem; }
    .p-entry_info .pp_area .form_input input[type="checkbox"] {
      width: 1.3em;
      height: 1.3em; }

/*-------------------------------
  PRIVACY POLYCY
-------------------------------*/
.pp_area a {
  color: #194C97; }
  @media screen and (min-width: 992px) {
    .pp_area a:hover {
      text-decoration: underline; } }

.pp_area p {
  margin-bottom: 2em; }

.pp_area .bold {
  font-weight: 600; }

.pp_area h4 {
  font-size: 1.2em;
  margin-bottom: 1em;
  position: relative;
  padding-left: 2em; }
  .pp_area h4 span {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block; }
    .pp_area h4 span:before {
      content: "";
      background: #194C97;
      width: 100%;
      height: 3px;
      display: block;
      position: absolute;
      bottom: -0.4em;
      left: 0; }

.pp_area ul {
  margin-bottom: 1.5em; }
  .pp_area ul li {
    padding-left: 1em;
    position: relative; }
    .pp_area ul li:before {
      content: "";
      background: #194C97;
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0.7em; }

/*-------------------------------
  THANKS
-------------------------------*/
.p-thanks .thanks {
  text-align: center; }

.p-thanks .c-btn02 {
  margin-top: 2rem; }

@media screen and (max-width: 575px) {
  .p-thanks {
    text-align: center; } }

/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound {
  padding: 24rem 0 16rem; }
  .p-notfound .c-btnwrap {
    margin-top: 8rem; }
  @media screen and (max-width: 575px) {
    .p-notfound {
      text-align: center; } }

/*--------------------------------------------
  print
--------------------------------------------*/
