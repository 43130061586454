@charset "utf-8";
/*============================================

  OTHER PAGE

============================================*/
.p-requirements_info{
  &__box.new{
    margin-bottom: 8rem;
    padding-bottom:8rem;
    border-bottom:1px solid #ddd;
  }
  &__tbl{
    td{
      em{
        font-style: initial;
        font-weight: 600;
        display: block;
        margin-top: 1.5em;
        color: $accent_color;
        &:first-child{
          margin-top: 0;
        }
      }
      span{
        display: inline-block;
        background: $accent_color;
        border-radius:5px;
        color: #fff;
        line-height: 1;
        padding:0.5em;
        margin-right: 0.5em;
      }
      ul{
        margin-top: 1em;
        &:first-child{
          margin-top: 0;
        }
        li{
          position: relative;
          padding-left: 1em;
          &:before{
            content: "";
            background: $accent_color;
            width: 8px;
            height: 8px;
            display: block;
            border-radius: 50%;
            position: absolute;
            top:0.7em;
            left: 0;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__tbl{
      td{
        span{
          margin-top: 0.5em;
        }
      }
    }
  }
}

/*-------------------------------
  NEWS
-------------------------------*/
.p-news{
  .c-news{
    background: $sub_color;
  }
  .c-news__list{
    transform: translateY(0rem);
    transition-delay: 1.5s;
    opacity: 1;
  }
}

.p-news_single{
  background: $sub_color;
  padding: 4em 0;
  .c-inner{
    background: #fff;
    border-radius: 15px;
    padding: 5em;
    @include mq(xs){
      padding: 2em;
    }
  }
  &__head{
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5em;
    margin-bottom: 2.5em;
  }
  &__info{
    display: flex;
    align-items: center;
    font-family: $base_font_en;
    line-height: 1;
    margin-bottom: 1em;
    time{
      font-size: 1.6em;
      margin-right: 1em;
    }
    .term{
      background: $font_color;
      color: #fff;
      font-size: 1.4em;
      padding: 0.1em 0.4em;
      @include mq(xs){
        font-size: 1.2em;
      }
    }
  }
  &__ttl{
    font-size: 2em;
    font-weight: 600;
    line-height: 1.4;
    @include mq(xs){
      font-size: 1.4em;
    }
  }
  &__body{
    margin-bottom: 3em;
  }
  &__foot{
    .c-btn01{
      max-width: 250px;
      text-align: center;
      margin: 0 auto;
    }
  }
}

/*-------------------------------
  ENTRY
-------------------------------*/
.p-entry_info{
  padding: 10rem 0 0;
  @include mq(sm){
    padding: 8rem 0 0;
  }
  .read{
    text-align: center;
    margin-bottom: 3rem;
  }
  .pp_area{
    .pp{
      border:1px solid #ddd;
      padding:1em;
      height: 20rem;
      overflow-y: scroll;
      margin-bottom: 1em;
    }
    .form_input{
      text-align: center;
      margin-bottom: 3rem;
      input[type="checkbox"]{
        width: 1.3em;
        height: 1.3em;
      } 
    }
  }
}


/*-------------------------------
  PRIVACY POLYCY
-------------------------------*/
.pp_area{
  a{
    color: $accent_color;
    @include mq(sm-){
      &:hover{
        text-decoration: underline;
      }
    }
  }
 p{
  margin-bottom: 2em;
 }
 .bold{
  font-weight: 600;
 }
 h4{
  font-size: 1.2em;
  margin-bottom: 1em;
  position: relative;
  padding-left:2em;
  span{
    position: absolute;
    left: 0;
    top:0;
    display: inline-block;
    &:before{
      content: "";
      background:$accent_color;
      width: 100%;
      height: 3px;
      display: block;
      position: absolute;
      bottom:-0.4em;
      left: 0;
    }
  }
 }
 ul{
  margin-bottom: 1.5em;
  li{
    padding-left: 1em;
    position: relative;
    &:before{
      content: "";
      background: $accent_color;
      width: 8px;
      height: 8px;
      display: block;
      border-radius:50%;
      position: absolute;
      left:0;
      top:0.7em;
    }
  }
 }
}

/*-------------------------------
  THANKS
-------------------------------*/
.p-thanks{
  .thanks{
    text-align: center;
  }
  .c-btn02{
    margin-top: 2rem;
  }
  @include mq(ss){
    text-align: center;
  }
}
/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound{
  padding: $margin_md*2+$margin_ss*4 0 $margin_md*2;
  .c-btnwrap{
    margin-top: $margin_md;
  }
  @include mq(ss){
    text-align: center;
  }
}