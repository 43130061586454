@charset "utf-8";

/*-----------------------------------------------------
  Wordpress EDITOR
------------------------------------------------------*/
.wp_editor * + p,
.wp_editor * + ul,
.wp_editor * + ol,
.wp_editor * + .table_wrap {
  margin-top: 1.5em;
}
.wp_editor * + h1,
.wp_editor * + h2,
.wp_editor * + h3,
.wp_editor * + h4 {
  margin-top: 2.5em;
}
.wp_editor h2,
.wp_editor h3,
.wp_editor h4 {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.1rem;
}
.wp_editor h2 {
  position: relative;
  padding-bottom: 0.5em;
  font-size: 2.6rem;
}
.wp_editor h2::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2em;
  height: 0.2rem;
  background-color: $main_color;
}
.wp_editor h3 {
  position: relative;
  padding-left: 1em;
  font-size: 2.4rem;
}
.wp_editor h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 0.4em;
  left: 0;
  width: 0.5em;
  height: 0.5em;
  background-color: $main_color;
}
.wp_editor h4 {
  font-size: 2rem;
}

.wp_editor a {
  color: $accent_color;
  font-weight: 600;
  word-break: break-all;
}
.wp_editor a:hover,
.wp_editor a:focus {
  opacity: 0.7;
}

.wp_editor ul,
.wp_editor ol {
  /*margin-bottom: 2rem;*/
  padding-left: 2em;
  line-height: 1.5;
}
.wp_editor ul > li:not(:first-child),
.wp_editor ol > li:not(:first-child) {
  margin-top: 1em;
}
.wp_editor ul > li {
  list-style-type: disc;
}
.wp_editor ol > li {
  list-style-type: decimal;
}

.wp_editor table {
  width: 100%;
}
.wp_editor table tr > th,
.wp_editor table tr > td {
  padding: 1em;
  border: 1px solid #ddd;
}
.wp_editor table tr > th {
  background-color: #a9a9a9;
}

/*  Wordpress EDITOR SP  */
@media screen and (max-width: 767px) {
  .wp_editor * + p,
  .wp_editor * + ul,
  .wp_editor * + ol,
  .wp_editor * + .table_wrap {
    margin-top: 1em;
  }
  .wp_editor * + h1,
  .wp_editor * + h2,
  .wp_editor * + h3,
  .wp_editor * + h4 {
    margin-top: 2em;
  }
  .wp_editor h2 {
    font-size: 2.1rem;
  }
  .wp_editor h3 {
    font-size: 1.9rem;
  }
  .wp_editor h4 {
    font-size: 1.8rem;
  }
  .wp_editor ul > li:not(:first-child),
  .wp_editor ol > li:not(:first-child) {
    margin-top: 0.6em;
  }
}
/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi{
  margin-top: $margin_md;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-family: "Passion One",cursive;
  font-size: 1.4em;
  >*{
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin: 0 .5em;
  }
  .current{
    opacity: 0.7;
  }

  a{
    &.previouspostslink{
      // position: relative;
      // padding-left: 2em;
      // margin-right: 3em;
      // &:before,
      // &:after {
      //   content:"";
      //   left: 0;
      // }
      // &:before{
      //   transform-origin: left bottom;
      //   transform:rotate(-45deg);
      // }
    }
    &.nextpostslink{
      // position: relative;
      // padding-right: 2em;
      // margin-left: 3em;
      // &:before,
      // &:after {
      //   content:"";
      //   right: 0;
      // }
      // &:before{
      //   transform-origin: right bottom;
      //   transform:rotate(45deg);
      // }
    }
    &:before,
    &:after {
      position: absolute;
      background: $font_color;
      top:.5em;
    }
    &:before{
      width: .45em;
      height: .5px;
    }
    &:after {
      width: 1.25em;
      height: 1px;
    }
    @include mq(sm-){
      &:hover{
        color:$main_color;
      }
    }
  }
  @include mq(ss){
    a{
      &.previouspostslink{
        margin-right: 1em;
      }
      &.nextpostslink{
        margin-left: 1em;
      }
    }
    .extend{
      display: none;
    }
  }
}