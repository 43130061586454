@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key {
  position: relative;
  overflow: hidden;
  p {
    position: absolute;
    width: 3.5%;
  }
  img {
    width: 100%;
  }
  .txt {
    // width: 60.7%;
    width: 61.9%;
    left: 48%;
    top: 33.3333%;
    transform: translate(-50%, -50%) scale(0);
    transition-duration: 0.3s;
  }
  .copy{
    width: 77.9%;
    bottom: 9%;
    left: 50%;
    transform: translate(-50%, 0) scale(0);
    transition-duration: 0.5s;
  }
  // .chara-l {
  //   width: 21.3%;
  //   left: 0;
  //   bottom: -28rem;
  //   transition-duration: 0.6s;
  //   transition-delay: 0.5s;
  // }
  // .chara-r {
  //   width: 19.4%;
  //   right: 0;
  //   bottom: -28rem;
  //   transition-duration: 0.6s;
  //   transition-delay: 1s;
  // }
  .chara-l02 {
    width: 16.6%;
    left: 8%;
    top: 50%;
    transform: translate(0, -50%) scale(0);
    transition-duration: 0.7s;
    transition-delay: 0.5s;
  }
  .chara-r02 {
    width: 31.8%;
    left: 85%;
    top: 39%;
    transform: translate(-50%, -50%) scale(0);
    transition-duration: 0.7s ;
    transition-delay: 1s;
  }
  .deco {
    animation: deco 0.8s linear 0s infinite;
  }
  // .deco-01 {
  //   width: 5.4%;
  //   left: 9%;
  //   top: 20%;
  // }
  // .deco-02 {
  //   left: 14.2%;
  //   top: 16%;
  // }
  // .deco-03 {
  //   left: 31.8%;
  //   top: 12%;
  // }
  // .deco-04 {
  //   right: 11.1%;
  //   top: 15%;
  // }
  // .deco-05 {
  //   left: 21.5%;
  //   bottom: 20%;
  // }
  // .deco-06 {
  //   right: 21%;
  //   bottom: 14%;
  // }
  .deco-07 {
    width: 10.7%;
    top: -10%;
    left: 2%;
    transform: translate(0, -50%);
  }
  .deco-08 {
    width: 10.7%;
    bottom: -9%;
    right: 0;
    transform: translate(-50%, -50%) scale(-1, 1);
  }
  &.is-anime {
    .txt {
      transform: translate(-50%, -50%) scale(1);
      transition-duration: 0.5s;
    }
    .copy{
      transform: translate(-50%, 0) scale(1);
      transition-duration: 0.7s;
    }
    // .chara-l {
    //   bottom: 0;
    //   z-index: 1;
    // }
    // .chara-r {
    //   bottom: 0;
    //   z-index: 1;
    // }
    .chara-l02{
      transform: translate(0, -50%) scale(1);
      transition-duration: 0.9s;
    }
    .chara-r02{
      transform: translate(-50%, -50%) scale(1);
      transition-duration: 0.9s ;
    }
    @keyframes deco {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1);
      }
    }
  }
  @include mq(xs) {
    &_bg {
      height: 20rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .txt {
      // width: 94%;
    }
    // .chara-l {
    //   width: 27%;
    // }
    // .chara-r {
    //   width: 24%;
    // }
    // .deco-05 {
    //   left: 30%;
    // }
    // .deco-06 {
    //   right: 30%;
    // }

  }
  @include mq(ss-xs){
    .txt{
      width: 50%;
      top: 37%;
    }
    .copy{
      width: 67%;
      bottom: 6%;
    }
    .chara-l02{
      top: 42%;
    }
    .chara-r02{
      width: 26%;
      top: 32%;
    }
  }
  @include mq(ss){
    .copy{
      bottom: 11%;
    }
  }
}

/*-------------------------------
  INDEX > SubCOpy
-------------------------------*/
.p-idx_subcopy{
  background: url('../img/index/sub_copy_bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  z-index: 2;
  position: relative;
  padding: 5rem 0;
  img{
    transform: translateY(6rem);
    transition-duration: 0.3s;
    opacity: 0;
    max-width: 100%;
  }
  &.is-anime {
    img {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
}

/*-------------------------------
  INDEX > NEWS
-------------------------------*/
.p-idx{
  .c-news__list{
    margin-bottom: 3em;
  }
}

/*-------------------------------
  INDEX > POINT
-------------------------------*/
.p-idx_point {
  padding: $margin_ll 0;
  &__list {
    counter-reset: number 0;
    justify-content: space-between;
  }
  li {
    width: 49%;
    background: $sub_color;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.3em 2em;
    @include mq(xs-) {
      &:nth-child(n + 3) {
        margin-top: 1.5em;
      }
    }
    .baloon {
      position: absolute;
      left: 0;
      top: -1em;
      z-index: 2;
      &:before {
        counter-increment: number 1;
        content: "0" counter(number);
        font-size: 4.8rem;
        display: block;
        font-family: $base_font_en;
        color: $font_color;
        line-height: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .icon {
      position: relative;
      background: $main_color;
      border-radius: 50%;
      width: 44%;
      padding-top: 44%;
      transform: scale(0);
      transition-duration: 0.6s;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
      }
    }
    .txt {
      width: 50%;
      font-size: 1.25vw;
      font-weight: 600;
      span {
        background: linear-gradient(transparent 80%, $main_color 80%);
      }
    }
  }
  &.is-anime {
    li {
      &:nth-child(1) {
        .icon {
          transition-delay: 0.5s;
        }
      }
      &:nth-child(2) {
        .icon {
          transition-delay: 1s;
        }
      }
      &:nth-child(3) {
        .icon {
          transition-delay: 1.5s;
        }
      }
      &:nth-child(4) {
        .icon {
          transition-delay: 2s;
        }
      }
      .icon {
        transform: scale(1);
      }
    }
  }
  @include mq(w1400) {
    li {
      .baloon {
        width: 18%;
        img {
          width: 100%;
        }
        &:before {
          font-size: 4.8rem;
        }
      }
      .icon {
        width: 35%;
        padding-top: 35%;
        img {
          max-width: 50%;
        }
      }
      .txt {
        width: 60%;
        font-size: 1.7vw;
      }
    }
  }
  @include mq(sm) {
    padding: $margin_lg 0;
    li {
      padding: 1em;
      .baloon {
        &:before {
          font-size: 3rem;
        }
      }
      .icon {
        width: 30%;
        padding-top: 30%;
        img {
          max-width: 60%;
        }
      }
      .txt {
        width: 65%;
        font-size: 2vw;
      }
    }
  }
  @include mq(xs) {
    padding: $margin_md 0;
    &__list {
      display: block;
      li {
        width: 100%;
        &:nth-child(n + 2) {
          margin-top: 2rem;
        }
        .baloon {
          width: 13%;
          &:before {
            font-size: 2.5rem;
          }
        }
        .txt {
          width: 65%;
          font-size: 4.6vw;
        }
      }
    }
  }
}
/*-------------------------------
  INDEX > MOVIE
-------------------------------*/
.p-idx_movie {
  padding: $margin_ll 0;
  &__slide-wrap {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 30%;
      transition-duration: 0.6s;
      opacity: 0;
    }
    &:before {
      background: url("../img/index/idx_movie_l.png") no-repeat;
      width: 138px;
      height: 251px;
      left: 7%;
      transition-delay: 1s;
    }
    &:after {
      background: url("../img/index/idx_movie_r.png") no-repeat;
      width: 136px;
      height: 235px;
      right: 7%;
      transition-delay: 1.5s;
    }
    &.is-anime {
      &:before,
      &:after {
        opacity: 1;
      }
      &:before {
        left: -1.4%;
      }
      &:after {
        right: -1.4%;
      }
    }
  }
  &__slide {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto 4rem;
    position: relative;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    z-index: 2;
    &:before {
      content: "";
      background: #fff;
      border-radius: 15px;
      width: 104%;
      height: 106%;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    li {
      position: relative;
      padding-top: 35em;
      width: 100%;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .slide-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
    }
    .prev-arrow {
      left: -3rem;
    }
    .next-arrow {
      right: -3rem;
      transform: translateY(-50%) rotate(180deg);
    }
    &.is-anime {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
  &__thum {
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    .slick-track {
      padding: 2.5% 0;
    }
    li {
      position: relative;
      padding-top: 11em;
      // max-width: 350px;
      width: 100%;
      height: 0;
      position: relative;
      margin: 0 2vw;
      &:before {
        content: "";
        background: #fff;
        width: 108%;
        height: 114%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px;
      }
      iframe {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &.is-anime {
      transform: translateY(0rem);
      opacity: 1;
    }

    &.-wrap {
      .slick-list {
        margin-top: -3em;
        .slick-track {
          width: 100% !important;
          transform: translate3d(0px, 0px, 0px) !important;

          .slick-slide {
            width: calc(33.3333% - 4vw) !important;
            margin-top: 3em;
          }
        }
      }
    }
  }
  @include mq(w1300) {
    &__slide-wrap {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  @include mq(sm) {
    padding: $margin_lg 0;

    &__slide {
      li {
        padding-top: 52vw;
      }
    }

    &__thum {
      &.-wrap {
        .slick-list {
          margin-top: -2em;
          .slick-track {
            .slick-slide {
              margin-top: 2em;
              padding-top: 7em;
            }
          }
        }
      }
    }
  }
  @include mq(xs) {
    padding: $margin_md 0;
    .slide-arrow {
      width: 4rem;
      height: 4rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .prev-arrow {
      left: -2rem;
    }
    .next-arrow {
      right: -2rem;
    }

    &__thum {
      &.-wrap {
        .slick-list {
          .slick-track {
            .slick-slide {
              width: calc(50% - 4vw) !important;
              padding-top: 9em;
            }
          }
        }
      }
    }
  }
  @include mq(ss) {
    &__thum {
      &.-wrap {
        .slick-list {
          .slick-track {
            .slick-slide {
              padding-top: 6em;
            }
          }
        }
      }
    }
  }
}

/*-------------------------------
  INDEX > INFOGRAPHICS
-------------------------------*/
.p-idx_infographics {
  padding: $margin_ll 0;
  &__date {
    background: #fff;
    padding: 5%;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: -9rem;
    }
    @include mq(md) {
      &:before,
      &:after {
        display: none;
      }
    }
    &:before {
      background: url("../img/index/idx_num_l.png") no-repeat;
      width: 100px;
      height: 177px;
      left: 0;
    }
    &:after {
      background: url("../img/index/idx_num_r.png") no-repeat;
      width: 98px;
      height: 177px;
      right: 0;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    width: 50%;
    line-height: 1;
    @include mq(xs-) {
      &:nth-child(n + 3) {
        margin-top: 4em;
      }
    }
    &.full {
      width: 100%;
      .img {
        width: 52%;
      }
      .txt {
        width: 100%;
        max-width: 12.3em;
        letter-spacing: 1.6px;
      }
      .annotation {
        text-align: right;
      }
    }
    &.employee {
      .img {
        width: 40%;
      }
    }
    &.colmun {
      width: 33%;
      display: block;
      text-align: center;
      .img {
        text-align: center;
        margin: 0 0 2rem;
        width: 100%;
        height: 17vh;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .sm {
        font-size: 2vw;
        margin-bottom: 2.5rem;
      }
      .num {
        font-size: 2.4vw;
        font-weight: 600;
        span {
          font-size: 4vw;
          font-weight: 600;
        }
      }
    }
    &.leave {
      .img {
        width: 25%;
      }
      .txt {
        width: 75%;
        text-align: center;
      }
      .annotation {
        max-width: 100%;
        text-align: right;
      }
    }
    &.time{
      width: 50%;
      display: block;
      text-align: center;
      .img {
        text-align: center;
        margin: 0 0 2rem;
        width: 100%;
        height: 17vh;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .sm {
        font-size: 2vw;
        margin-bottom: 2.5rem;
      }
      .num {
        font-size: 2.4vw;
        font-weight: 600;
        span {
          font-size: 4vw;
          font-weight: 600;
        }
      }
    }
    &.is-anime {
      .img {
        transform: scale(1);
      }
      &.item01 {
        .img {
          transition-delay: 0.5s;
        }
      }
      &.item02 {
        .img {
          transition-delay: 1s;
        }
      }
      &.item03 {
        .img {
          transition-delay: 0.5s;
        }
      }
      &.item04 {
        .img {
          transition-delay: 0.5s;
        }
      }
      &.item05 {
        .img {
          transition-delay: 1s;
        }
      }
      &.item06 {
        .img {
          transition-delay: 0.3s;
        }
      }
      &.item07 {
        .img {
          transition-delay: 0.6s;
        }
      }
      &.item08 {
        .img {
          transition-delay: 0.9s;
        }
      }
      &.item09 {
        .img {
          transition-delay: 1.2s;
        }
      }
      &.item10 {
        .img {
          transition-delay: 1.5s;
        }
      }
    }
  }
  .img {
    margin-right: 1em;
    width: 45%;
    transform: scale(0);
    transition-duration: 0.6s;
    img {
      max-width: 100%;
    }
  }
  .txt {
    font-size: 2.3vw;
    font-weight: 600;
    width: 55%;
  }
  .lg {
    margin-bottom: 0.3em;
  }
  .num {
    span {
      font-size: 5vw;
    }
  }
  .annotation {
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
  }
  @include mq(sm) {
    padding: $margin_lg 0;
    &__item {
      &.full {
        .txt {
          max-width: 13.3em;
        }
      }
      &.leave {
        .annotation {
          text-align: center;
        }
      }
    }
    &__item.colmun {
      .sm {
        font-size: 3.1vw;
      }
      .img {
        height: 12vh;
        margin-bottom: 2rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &__item.time{
      .sm {
        font-size: 3.1vw;
      }
      .img {
        height: 12vh;
        margin-bottom: 2rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .txt {
      font-size: 2.9vw;
    }
    .num {
      span {
        font-size: 7vw;
      }
    }
    .annotation {
      font-size: 1.6rem;
      margin-top: 1rem;
    }

  }
  @include mq(xs) {
    padding: $margin_md 0;
    &__date {
      display: block;
    }
    &__item {
      width: 100%;
      border-bottom: 2px solid #eaeaea;
      padding-bottom: 2em;
      &:nth-child(n + 2) {
        margin-top: 2em;
      }
      &:last-child {
        border: none;
      }
      &.full {
        display: block;
        .img {
          width: 100%;
          margin-bottom: 0.5em;
        }
        .txt {
          width: 100%;
        }
        .annotation {
          text-align: right;
        }
      }
      &.colmun,
      &.time {
        width: 100%;
        .img {
          width: 100%;
          height: 10rem;
        }
        .sm {
          font-size: 3rem;
          margin-bottom: 1.5rem;
        }
        .num {
          font-size: 4rem;
          font-weight: 600;
          span {
            font-size: 5rem;
          }
        }
      }
    }
    .txt {
      font-size: 2.8rem;
    }
    .num {
      span {
        font-size: 5rem;
      }
    }
  }
}

/*-------------------------------
  INDEX > RECRUIT
-------------------------------*/
.p-question{
  padding: 0 0 $margin_ll;
  @include mq(sm){
    padding: 0 0 $margin_lg;
  }
  @include mq(xs){
    padding: 0 0 $margin_md;
  }
}
.p-question_box {
  $amp: &;
  @include mq(xs-){
    display: flex;
    justify-content: space-between;
    >div{
      width: 48.3%;
    }
  }
  &__item{
    @include mq(xs-){
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    padding: 1em;
    background: #fff;
    font-size: 3.6rem;
    &:nth-child(n+2){
      margin-top: 1em;
    }
    &.-carimg{
      position: relative;
      @include mq(md-){
        &:before{
          content: "";
          background: url("../img/index/car_image.png") no-repeat;
          width: 134px;
          height: 79px;
          display: block;
          position: absolute;
          left: -0.5em;
          bottom: 0.5em;
        }
      }
    }
    &.js-anime{
      transform: translateY(6rem);
      transition-duration: 0.3s;
      opacity: 0;
      &.is-anime{
        transform: translateY(0rem);
        opacity: 1;
      }
    }
    img{
      max-width: 100%;
    }
  }
  &__ttl{
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: .75em;
    @include mq(md-){
      &.-w5em{
        margin-bottom: 0;
        width: 5.5em;
        + #{$amp}__images{
          width: calc(100% - 5.5em);
        }
      }
    }
  }
  &__images{
    text-align: right;
  }
  @include mq(md){
    &__item{
      text-align: center;
    }
    &__ttl{
      text-align: center;
      width: 100%;
    }
    &__images{
      text-align: center;
      margin: auto;
    }
  }
  @include mq(sm){
    &__item{
      font-size: 2.6rem;
    }
  }
  @include mq(xs){
    &__item{
      font-size: 2.4rem;
    }
    &__right{
      margin-top: 1.5em;
    }
  }
}

/*-------------------------------
  INDEX > INSTAGRAM
-------------------------------*/
.p-insta{
  padding: $margin_ll 0;
  @include mq(sm){
    padding: $margin_lg 0;
  }
  @include mq(xs){
    padding: $margin_md 0;
  }
}

/*-------------------------------
  INDEX > VOICE
-------------------------------*/
.p-idx_voice {
  padding: $margin_ll 0;
  .c-inner {
    max-width: 100%;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    li {
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translateY(6rem);
      opacity: 0;
      transition-duration: 0.6s;
      &:nth-child(1) {
        transition-delay: 0.5s;
      }
      &:nth-child(2) {
        transition-delay: 1s;
      }
      &:nth-child(3) {
        transition-delay: 1.5s;
      }
      &:nth-child(4) {
        transition-delay: 2s;
      }
      &:nth-child(5) {
        transition-delay: 2.5s;
      }
      &:nth-child(6) {
        transition-delay: 3s;
      }
      &:nth-child(7) {
        transition-delay: 3.5s;
      }
      &:nth-child(8) {
        transition-delay: 4s;
      }
      @include mq(xs-) {
        &:nth-child(n + 1):nth-child(even) {
          margin-top: 4rem;
        }
        &:nth-child(even) {
          flex-direction: row-reverse;
          transform: translateY(10rem);
          .p-idx_voice__txt:before {
            left: initial;
            right: -1em;
            border-right: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-left: 30px solid $sub_color;
          }
        }
      }
    }
  }
  &__icon {
    background: $main_color;
    position: relative;
    width: 27%;
    padding-top: 27%;
    border-radius: 50%;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
    }
  }
  &__txt {
    width: 66%;
    background: $sub_color;
    border-radius: 5px;
    padding: 1em;
    font-size: 2.2rem;
    position: relative;
    font-weight: 600;
    line-height: 1.4;
    &:before {
      content: "";
      border-top: 20px solid transparent;
      border-right: 30px solid $sub_color;
      border-bottom: 20px solid transparent;
      position: absolute;
      left: -1em;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.is-anime {
    .p-idx_voice__list {
      li {
        transform: translateY(0rem);
        opacity: 1;
      }
    }
  }
  @include mq(sm) {
    padding: $margin_lg 0 15rem;
    .c-inner {
      max-width: 92%;
    }
    &__icon {
      width: 9rem;
      height: 9rem;
      padding-top: 0;
      img {
        width: auto;
        height: 70%;
      }
    }
    &__txt {
      font-size: 1.6rem;
    }
  }
  @include mq(xs) {
    padding: $margin_lg 0;
    &__list {
      display: block;
      li {
        width: 100%;
        &:nth-child(n + 2) {
          margin-top: 1rem;
        }
      }
    }
    &__txt {
      font-size: 1.5rem;
      position: relative;
      &:before {
        left: -1em;
        border-top: 10px solid transparent;
        border-right: 20px solid #fffbe4;
        border-bottom: 10px solid transparent;
      }
    }
  }
}

/*-------------------------------
  INDEX > PHOTO
-------------------------------*/
.p-idx_photo {
  padding: $margin_ll 0;
  &__list {
    position: relative;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.5s;
    li.slick-slide {
      border: 10px solid #fff;
      border-radius: 15px;
      overflow: hidden;
      margin: 0 1rem;
      img {
        max-width: 100%;
      }
    }
    .slide-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
    }
    .prev-arrow {
      left: -1em;
    }
    .next-arrow {
      right: -1em;
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .slick-dots {
    bottom: -6rem;
    li {
      button {
        color: #fff;
        &:before {
          color: #fff;
          opacity: 1;
          font-family: initial;
          font-size: 3.5rem;
        }
      }
      &.slick-active {
        button {
          position: relative;
          &:after {
            content: "";
            border: 3px solid $font_color;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
  &.is-anime {
    .p-idx_photo__list {
      transform: translateY(0rem);
      opacity: 1;
    }
  }
  @include mq(sm) {
    padding: $margin_lg 0;
    &__list {
      .slide-arrow {
        height: 4rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .prev-arrow {
        left: -1em;
      }
      .next-arrow {
        right: -1em;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

/*-------------------------------
  INDEX > BENEFITS
-------------------------------*/
.p-idx_benefits {
  padding: $margin_ll 0;
  overflow: hidden;
  z-index: 3;
  position: relative;
  background: #fff;
  .js-anime{
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: .6s;
  }
  .is-anime {
    transform: translateY(0rem);
    opacity: 1;
  }
  @include mq(sm) {
    padding: $margin_lg 0;
  }
  .c-ttl01{
    position: relative;
    margin-bottom: 8rem;
    @include mq(md-){
      &:before,
      &:after{
        content: "";
        display: block;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
      }
      &:before{
        background: url("../img/index/benefits_ttl03.png") no-repeat;
        width: 126px;
        height: 236px;
        left: 0;
      }
      &:after{
        background: url("../img/index/benefits_ttl04.png") no-repeat;
        width: 171px;
        height: 220px;
        right: 0;
      }
    }
    @include mq(xs){
      margin-bottom: 4rem;
    }
  }
  &__ttl{
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 1;
    position: relative;
    @include mq(sm-){
      width: 25%;
    }
    h3{
      @include mq(sm-){
        padding-left: 1.8em;
      }
      position: relative;
      &:before{
        content: "";
        background: url("../img/common/icon_arrow03.png") no-repeat;
        width: 45px;
        height: 62px;
        display: block;
        background-size: contain;
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    @include mq(sm){
      font-size: 2.8rem;
      text-align: center;
      margin-bottom: 1em;
      display: inline-block;
      padding-left: 2em;
      width: 100%;
      h3{
        display: inline-block;
        &:before{
          left: -1.6em;
          width: 34px;
          height: 51px;
        }
      }
    }
  }
  &__inner{
    @include mq(sm-){
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    padding: 2em;
    position: relative;
    &:nth-child(odd){
      background: $main_color;
    }
    &:nth-child(even){
      background: $sub_color;
    }
    &:before{
      content: "";
      display: block;
      position: absolute;
      left: 6em;
      bottom: 0;
      transform: translateY(50%);
      z-index: 2;
    }
    @include mq(sm-){
      &:nth-child(1){
        z-index: 7;
        &:before{
          background: url("../img/index/car_image.png") no-repeat;
          width: 134px;
          height: 79px;
        }
      }
      &:nth-child(2){
        z-index: 6;
        &:before{
          background: url("../img/common/icon_pad.png") no-repeat;
          width: 95px;
          height: 109px;
        }
      }
      &:nth-child(3){
        z-index: 5;
        &:before{
          background: url("../img/common/icon_meat.png") no-repeat;
          width: 147px;
          height: 86px;
        }
      }
      &:nth-child(4){
        z-index: 4;
        &:before{
          background: url("../img/common/c-ttl_r.png") no-repeat;
          width: 80px;
          height: 65px;
          left: 0;
        }
      }
      &:nth-child(5){
        z-index: 3;
        &:before{
          background: url("../img/common/icon_pencil.png") no-repeat;
          width: 140px;
          height: 98px;
        }
      }
      &:nth-child(6){
        z-index: 2;
        &:before{
          background: url("../img/common/icon_spanner.png") no-repeat;
          width: 156px;
          height: 95px;
        }
      }
    }
    @include mq(xs){
      padding: 2em 0.5em;
    }
  }
  &__box{
    @include mq(xs-){
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    @include mq(sm-){
      width: 74.5%;
    }
    li{
      @include mq(xs-){
        width: 49.5%;
      }
      border:4px solid #000;
      border-radius: 10px;
      background: #fff;
      padding: 1.5em;
      position: relative;
      &:nth-child(n+3){
        margin-top: 0.5em;
      }
      &:before{
        content: "";
        background: url("../img/common/icon_ribon.png")  no-repeat;
        width: 65px;
        height: 51px;
        display: block;
        position: absolute;
        right: -0.7em;
        top:-0.7em;
        background-size: contain;
      }
      &.-full{
        width: 100%;
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top: 0.5em;
        }
      }
    }
    span{
      display: block;
      font-size: 2.4rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.4em;
      line-height: 1.3;
      @include mq(xs){
        font-size: 2rem;
      }
    }
    p{
      line-height: 1.6;
    }
  }
  &__bubble{
    position: relative;
    @include mq(md-){
      &::before{
        content: url(../img/index/benefits_bubble.png);
        position: absolute;
        // background:  url("../img/index/benefits_bubble.png")  no-repeat;
        position: absolute;
        width: 70%;
        height: 180px;
        top: 50%;
        left: 48%;
        transform: translate(-50%,-50%);
        @media (min-width: 1200px) and (max-width: 1400px) {
            width: 56%;
            left: 40%;
        }
      }
    }
  }
}

.p-idx_benefits_more {
  $amp: &;
  position: relative;
  max-width: 104rem;
  margin: 0 auto;

  &__whbox {
    padding: 1.2em;
    background-color: #fff;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 0.4s;
  }

  &__deco01,
  &__deco02 {
    position: absolute;
    bottom: 0;
    transform: translateY(6rem);
    opacity: 0;
    transition-duration: 0.4s;
    transition-delay: 1.6s;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: url("../img/common/c-ttl_l.png") center/contain no-repeat;
      width: 6.1rem;
      height: 5.6rem;
      animation: t-deco 2s linear 0s infinite;
    }
  }
  &__deco01 {
    left: -12rem;
    width: 25.6rem;

    &::before {
      top: 2em;
      right: 0;
    }
  }
  &__deco02 {
    right: -4rem;
    width: 22.3rem;

    &::before {
      top: 2em;
      left: -2em;
    }
  }

  &__btn {
    z-index: 1;
    position: relative;
    margin-top: -8rem;
    opacity: 0;
    transition-duration: 0.6s;
    transition-delay: 1s;

    > a {
      margin: 0 auto;
    }
  }

  .is-anime & {
    #{$amp}__whbox,
    #{$amp}__deco01,
    #{$amp}__deco02 {
      transform: translateY(0rem);
      opacity: 1;
    }
    #{$amp}__btn {
      opacity: 1;
    }
  }

  @include mq(w1400) {
    &__deco01 {
      left: -8rem;
    }
  }
  @include mq(md) {
    &__deco01 {
      left: -4rem;
      width: 21rem;
    }
    &__deco02 {
      width: 20rem;
    }
  }
  @include mq(sm) {
    &__whbox {
      padding: 1em;
    }

    &__deco01,
    &__deco02 {
      &::before {
        width: 4.4rem;
        height: 4rem;
      }
    }
    &__deco01 {
      left: -2rem;
      width: 16rem;

      &::before {
        top: 1em;
        right: -1em;
      }
    }
    &__deco02 {
      right: -2rem;
      width: 14rem;

      &::before {
        top: 1em;
      }
    }

    &__btn {
      margin-top: -6rem;
    }
  }
  @include mq(ss) {
    &__deco01,
    &__deco02 {
      bottom: 3em;
    }
    &__deco01 {
      left: -2rem;
      width: 10rem;

      &::before {
        top: 0.5em;
        right: -1.5em;
      }
    }
    &__deco02 {
      right: -2rem;
      width: 8rem;

      &::before {
        top: 0;
        left: -2.5em;
      }
    }

    &__btn {
      margin-top: -3rem;
    }
  }
}

/*-------------------------------
  INDEX > comment
-------------------------------*/
.p-index_comment{
  padding: 7rem 0;
  text-align: center;
  position: relative;
  img{
    max-width: 100%;
  }
  &.js-anime{
    img{
      transform: translateY(6rem);
      transition-duration: 0.3s;
      opacity: 0;
    }
    &.is-anime{
      img{
        transform: translateY(0rem);
        opacity: 1;
      }
    }
  }
  &:before{
    content: ""; 
    border-top: 6rem solid $main_color;
    border-right: 15rem solid transparent;
    border-left: 15rem solid transparent;
    position: absolute;
    left: 50%;
    bottom: -6rem;
    transform: translateX(-50%);
  }
}